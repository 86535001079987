import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CardMedia,
  Typography,
  IconButton
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const NewsDialog = ({ dialogOpen, handleClose, selectedNews, almaalee }) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const handleImageClick = () => {
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        {selectedNews && (
          <>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', direction: 'rtl' }}>
              {selectedNews.title}
            </DialogTitle>
            <DialogContent dividers>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  direction: 'rtl' // Apply RTL direction
                }}
              >
                <Box
                  sx={{
                    flex: '1 1 auto',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: { xs: 2, sm: 0 },
                    position: 'relative',
                    direction: 'rtl'
                  }}
                >
                  <CardMedia
                    component="img"
                    image={selectedNews.image || almaalee}
                    alt={selectedNews.title}
                    sx={{
                      maxHeight: '300px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                      cursor: 'pointer'
                    }}
                    onClick={handleImageClick}
                  />
                  {selectedNews.image && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        p: 1,
                        cursor: 'pointer',
                        color: 'primary.main',
                      }}
                      onClick={handleImageClick}
                    >
                      <FullscreenIcon />
                    </Box>
                  )}
                </Box>
                <Box sx={{ flex: '2 1 auto', pl: { sm: 2 }, direction: 'rtl' }}>
                  <Typography variant="body2" color="textSecondary" gutterBottom sx={{ fontStyle: 'italic', direction: 'rtl' }}>
                    {new Date(selectedNews.createdAt).toLocaleDateString('ar-EG', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', lineHeight: 1.6, direction: 'rtl' }}>
                    {selectedNews.content}
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                إغلاق
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Enlarged Image Modal */}
      <Dialog open={imageModalOpen} onClose={handleImageModalClose} maxWidth="md" fullWidth>
        <Box sx={{ position: 'relative', direction: 'rtl' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleImageModalClose}
            sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1201 }}
          >
            <FullscreenExitIcon />
          </IconButton>
          <CardMedia
            component="img"
            image={selectedNews?.image || almaalee}
            alt={selectedNews?.title}
            sx={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default NewsDialog;
