import React, {useState} from 'react';
import {Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import InfoIcon from '@mui/icons-material/Info';
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {jwtDecode} from "jwt-decode";
import swal from "sweetalert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from "@mui/icons-material/Close";

const MainDrawer = ({sidebarOpen, setSidebarOpen, BASE_URL}) => {
  const [userName, setUserName] = useState();
  const [loginOpen, setLoginOpen] = useState(false);
  const [password, setPassword] = useState('');

  async function loginUser (credentials) {
    const apiUrl = BASE_URL + 'login';
    return fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await loginUser({userName, password});

      if (response.status === 200) {
        const responseData = await response.json();
        const {accessToken, admin, id} = responseData;
        const decodedToken = jwtDecode(accessToken);
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken); // Store actual token
          if (admin) {
            localStorage.setItem("admin", "true");
            localStorage.setItem("supervisorAdmin", decodedToken.supervisorAdmin);
            localStorage.setItem("classes", JSON.stringify(decodedToken.permissions));
            window.location.href = "/studentInfoAll";
          } else {
            localStorage.setItem('id', id);
            localStorage.removeItem('admin');
            window.location.href = "/profile";
          }
        }
      } else if (response.status === 404) {
        await swal("فشل", "البريد الإلكتروني أو كلمة المرور خاطئة.");
      } else {
        await swal("فشل", "خطأ");
      }
    } catch (error) {
      console.error('Login error:', error);
      await swal("فشل", "حدث خطأ ما. حاول مرة أخرى.");
    }
  };
  const handleBackClick = () => {
    // Handle back navigation logic here
    setLoginOpen(false);
    // You can add additional logic to open the previous sidebar if needed
  };
  return (
    <Drawer
      anchor="right"
      open={sidebarOpen}
      onClose={() => setSidebarOpen(false)}
    >
      <Box
        sx={{
          width: 240,
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <List>
          {/* Close Sidebar List Item */}
          <ListItem  onClick={() => setSidebarOpen(false)}>
            <ListItemText primary="إغلاق القائمة" /> {/* Text for the close button */}
            <ListItemIcon>
              <CloseIcon /> {/* Icon for the close button */}
            </ListItemIcon>
          </ListItem>

          {/* Home List Item */}
          <ListItem button>
            <ListItemText primary="الرئيسية" /> {/* Text for the home button */}
            <ListItemIcon>
              <SchoolIcon /> {/* Icon for the home button */}
            </ListItemIcon>
          </ListItem>

          {/* AboutPage School List Item */}
          <ListItem >
            <ListItemText primary="معلومات المدرسة" /> {/* Text for the about button */}
            <ListItemIcon>
              <InfoIcon /> {/* Icon for the about button */}
            </ListItemIcon>
          </ListItem>

          {/* Login List Item */}
          <ListItem button onClick={() => setLoginOpen(true)}> {/* Opens the login dialog/modal */}
            <ListItemText primary="تسجيل الدخول" /> {/* Text for the login button */}
            <ListItemIcon>
              <LockOutlinedIcon /> {/* Icon for the login button */}
            </ListItemIcon>
          </ListItem>
        </List>

        {/* Login Drawer */}
        <Drawer
          anchor="right"
          open={loginOpen}
          onClose={() => setLoginOpen(false)}
          PaperProps={{
            sx: {
              width: { xs: '100%', sm: 400 }, // Full width on mobile, fixed width on larger screens
              padding: 3,
              backgroundColor: '#f5f5f5', // Light background for better contrast
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            {/* Back Button */}
            <IconButton
              sx={{
                position: 'absolute',
                top: 16,
                left: 16,
              }}
              onClick={handleBackClick}
            >
              <ArrowBackIcon />
            </IconButton>

            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mt: 1 }}>
              تسجيل الدخول
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px', marginTop: '20px' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                label="البريد الإلكتروني"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                sx={{ mb: 2, backgroundColor: '#fff', borderRadius: 1 }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label="كلمة المرور"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2, backgroundColor: '#fff', borderRadius: 1 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  py: 1.5,
                  fontSize: '16px',
                  textTransform: 'none',
                  borderRadius: '8px',
                }}
              >
                تسجيل الدخول
              </Button>
            </form>
            <br/>


          </Box>
        </Drawer>
      </Box>
    </Drawer>
  );
};

export default MainDrawer;
