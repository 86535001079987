import React, {useEffect, useState} from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    CircularProgress, Typography, TextField, Box, Paper
} from '@mui/material';
import {styled} from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)({
    backgroundColor: '#3f51b5',
    color: '#fff',
    textAlign: 'center',
});

const StyledDialogContent = styled(DialogContent)({
    backgroundColor: '#f5f5f5',
    padding: '20px',
});

const PunishmentNote = styled(Paper)({
    marginBottom: '16px',
    padding: '16px',
    backgroundColor: '#FFCCCB',  // Light yellow background
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
});

const StyledButton = styled(Button)({
    margin: '0 8px',
});

const PunishmentsDialog = ({isOpen, onClose, selectedRow, BASE_URL}) => {
    const [punishments, setPunishments] = useState([]);
    const [newPunishmentTitle, setNewPunishmentTitle] = useState('');
    const [newPunishmentBody, setNewPunishmentBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isOpen && selectedRow) {
            fetchPunishments();
        }
    }, [isOpen, selectedRow]);

    const fetchPunishments = async () => {
        setLoading(true);
        setError(null);
        const apiUrl = BASE_URL + 'getPunishments';  // Assuming the endpoint is 'getPunishments'
        try {
            const params = new URLSearchParams({id: selectedRow.id});
            const fullUrl = `${apiUrl}?${params.toString()}`;
            const response = await fetch(fullUrl);
            if (!response.ok) {
                throw new Error('Failed to fetch punishments');
            }
            const data = await response.json();
            setPunishments(data || []);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    let punishment = {
        title: String,
        body: String

    }
    const handleAddPunishment = async () => {
        if (!newPunishmentTitle.trim() || !newPunishmentBody.trim()) {
            return;
        }

        const apiUrl = BASE_URL + 'addPunishment';
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    studentId: selectedRow.id,
                    title: newPunishmentTitle,
                    body: newPunishmentBody
                })
            });

            if (!response.ok) {
                throw new Error('Failed to add punishment');
            }
            punishment = {...punishment, title: newPunishmentTitle, body: newPunishmentBody};
            setPunishments([...punishments, punishment]);
            setNewPunishmentTitle('');
            setNewPunishmentBody('');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <Box textAlign="right">
                <StyledDialogTitle align={'center'}
                                   style={{backgroundColor: '#f44336', color: '#fff'}}>العقوبات</StyledDialogTitle>
                <StyledDialogContent dividers>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress/>
                        </Box>
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <>
                            <Box maxHeight="400px" overflow="auto">
                                {punishments.length > 0 ? (
                                    punishments.map((punishment, index) => (
                                        <PunishmentNote key={index}>
                                            <Typography variant="h6">عقوبة {index + 1}</Typography>
                                            <Typography
                                                variant="subtitle1"><strong> {punishment.title}</strong></Typography>
                                            <Typography variant="body1">{punishment.body}</Typography>
                                        </PunishmentNote>
                                    ))
                                ) : (
                                    <Typography>لا توجد عقوبات متوفرة.</Typography>
                                )}

                            </Box>
                            <Box mt={2}>
                                <TextField
                                    fullWidth
                                    label="عنوان العقوبة الجديدة"
                                    value={newPunishmentTitle}
                                    onChange={(e) => setNewPunishmentTitle(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    InputLabelProps={{style: {direction: 'rtl'}}}
                                    inputProps={{style: {direction: 'rtl'}}}
                                />
                                <TextField
                                    fullWidth
                                    label="نص العقوبة الجديدة"
                                    value={newPunishmentBody}
                                    onChange={(e) => setNewPunishmentBody(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    InputLabelProps={{style: {direction: 'rtl'}}}
                                    inputProps={{style: {direction: 'rtl'}}}
                                />
                            </Box>
                        </>
                    )
                    }
                </StyledDialogContent>
                <DialogActions>
                    <StyledButton onClick={onClose} color="secondary" variant="contained">
                        إغلاق
                    </StyledButton>
                    <StyledButton onClick={handleAddPunishment} color="primary" variant="contained">
                        إضافة عقوبة
                    </StyledButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default PunishmentsDialog;

