import React from 'react';

const Message = ({ text, source, date }) => {
    const messageClass = source === 'Management' ? 'message management':'message family' ;
    return (
        <div className={messageClass}>
            <div className="message-header">
                <span className="sender">{source}</span>
                <span className="timestamp">{date.toLocaleString()}</span>
            </div>
            <div className="message-body">{text}</div>
        </div>
    );
};

export default Message;