import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const YearResultDialog = ({ isOpen, onClose ,selectedRow, BASE_URL}) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Year Result</DialogTitle>
            <DialogContent>
                {/* Add your year result content here */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                  إغلاق
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default YearResultDialog;
