import React, {useState} from 'react';
import {AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {NavLink, useNavigate} from 'react-router-dom';
import useStyles from './NavbarStyles';
import AndroidIcon from "@mui/icons-material/Android"; // Correctly import the styles
import { BASE_URL }  from "../../utils/BaseUrl"

function Navbar () {
  const classes = useStyles(); // Call useStyles to get the classes object
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const supervisorAdmin = JSON.parse(localStorage.getItem('supervisorAdmin'));
  const isAdmin = JSON.parse(localStorage.getItem('admin')); // Check if the user is an admin
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    localStorage.removeItem("admin");
    navigate('/login');
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar dir={'rtl'}>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon/>
          </IconButton>
          <Typography edge="start" variant="h6" className={classes.title}>
            مدرسة المعالي
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        classes={{paper: classes.drawerPaper}}
      >
        {/* Spacer to push content below toolbar */}
        <div className={classes.toolbar}/>

        {/* Flexbox container to control layout */}
        <div
          className={classes.drawerContent}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
          }}
        >
          {/* List content stays at the top */}
          <List>
            {supervisorAdmin && (
              <ListItem
                component={NavLink}
                to="/adminDashboard"
                onClick={toggleDrawer}
                button
              >
                <ListItemText primary="إضافة المشرف"/>
              </ListItem>
            )}

            <ListItem
              component={NavLink}
              to="/studentInfoAll"
              onClick={toggleDrawer}
              button
            >
              <ListItemText primary="احصل على الطالب"/>
            </ListItem>

            {supervisorAdmin && (
              <ListItem
                component={NavLink}
                to="/siteContentControl"
                onClick={toggleDrawer}
                button
              >
                <ListItemText primary="إدارة محتوى الموقع"/>
              </ListItem>
            )}

            {supervisorAdmin && (
              <ListItem
                component={NavLink}
                to="/generateStudentsCards"
                onClick={toggleDrawer}
                button
              >
                <ListItemText primary="تنزيل بطاقات الطلاب"/>
              </ListItem>
            )}

            {supervisorAdmin && (
              <ListItem
                component={NavLink}
                to="/studentsExamResults"
                onClick={toggleDrawer}
                button
              >
                <ListItemText primary="نتائج امتحانات الطلاب"/>
              </ListItem>
            )}

            {supervisorAdmin && (
              <ListItem
                component={NavLink}
                to="/studentsWeeklyExamResults"
                onClick={toggleDrawer}
                button
              >
                <ListItemText primary="امتحانات الطلاب الاسبوعية"/>
              </ListItem>
            )}
          </List>

          {/* Buttons stay at the bottom */}
          <div>
            {/* Logout Button */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.logoutButton}
              onClick={handleLogout}
            >
              تسجيل خروج
            </Button>

            {/* Download App Button */}
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 2, // Smaller margin between buttons
                py: 1.5, // Padding on Y axis
                fontSize: '16px',
                textTransform: 'none',
                borderRadius: '8px',
                backgroundColor: 'green', // Green background
                '&:hover': {
                  backgroundColor: 'darkgreen', // Darker green on hover
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              href={`${BASE_URL}api/download/apk/admin`}
              download
              startIcon={<AndroidIcon/>}
            >
              تحميل التطبيق
            </Button>
          </div>
        </div>
      </Drawer>


      <main className={classes.content}>
        {/* Your main content goes here */}
      </main>
    </div>
  );
}

export default Navbar;

