import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button,
    CircularProgress, Typography, TextField, Box
} from '@mui/material';

const RewardsDialog = ({ isOpen, onClose, selectedRow, BASE_URL }) => {
    const [rewards, setRewards] = useState([]);
    const [newRewardTitle, setNewRewardTitle] = useState('');
    const [newRewardBody, setNewRewardBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isOpen && selectedRow) {
            fetchRewards();
        }
    }, [isOpen, selectedRow]);

    const fetchRewards = async () => {
        setLoading(true);
        setError(null);
        const apiUrl = BASE_URL + 'getRewards';  // Assuming the endpoint is 'getRewards'
        try {
            const params = new URLSearchParams({ id: selectedRow.id });
            const fullUrl = `${apiUrl}?${params.toString()}`;
            const response = await fetch(fullUrl);
            if (!response.ok) {
                throw new Error('Failed to fetch rewards');
            }
            const data = await response.json();
            setRewards(data || []);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    let reward = {
        title: String,
        body: String

    }
    const handleAddReward = async () => {
        if (!newRewardTitle.trim() || !newRewardBody.trim()) {
            return;
        }

        const apiUrl = BASE_URL + 'addReward';
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    studentId: selectedRow.id,
                    title: newRewardTitle,
                    body: newRewardBody
                })
            });

            if (!response.ok) {
                throw new Error('Failed to add reward');
            }

            reward = {...reward, title: newRewardTitle, body: newRewardBody};
            setRewards([...rewards, reward]);
            setNewRewardTitle('');
            setNewRewardBody('');
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <Box direction="rtl" textAlign="right">
                <DialogTitle align="center" style={{ backgroundColor: '#f44336', color: '#fff' }}>المكافآت</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Typography color="error">{error}</Typography>
                    ) : (
                        <>
                        <Box maxHeight="400px" overflow="auto">
                            {rewards.length > 0 ? (
                                rewards.map((reward, index) => (
                                    <div key={index} style={{ marginBottom: '16px' }}>
                                        <Typography variant="h6">مكافأة {index + 1}</Typography>
                                        <Typography variant="subtitle1"><strong>{reward.title}</strong></Typography>
                                        <Typography variant="body1">{reward.body}</Typography>
                                    </div>
                                ))
                            ) : (
                                <Typography>لا توجد مكافآت متوفرة.</Typography>
                            )}
                        </Box>
                            <Box mt={2}>
                                <TextField
                                    fullWidth
                                    label="عنوان المكافأة الجديد"
                                    value={newRewardTitle}
                                    onChange={(e) => setNewRewardTitle(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    InputLabelProps={{ style: { direction: 'rtl' } }}
                                    inputProps={{ style: { direction: 'rtl' } }}
                                />
                                <TextField
                                    fullWidth
                                    label="نص المكافأة الجديدة"
                                    value={newRewardBody}
                                    onChange={(e) => setNewRewardBody(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    InputLabelProps={{ style: { direction: 'rtl' } }}
                                    inputProps={{ style: { direction: 'rtl' } }}
                                />
                            </Box>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" variant="contained">
                        إغلاق
                    </Button>
                    <Button onClick={handleAddReward} color="primary" variant="contained" size="large">
                        إضافة مكافأة
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default RewardsDialog;
