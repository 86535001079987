import React from "react";
import "./StudentIDCard.css";
import almomayzonSchoolLogo from '../assets/almomayzon.png';
import almaaleeSchoolLogo from '../assets/ms_logo.png';

const IDCard = ({ qrCode, Name, Class, Phone, Address, StudentNum, StudentImage }) => {

  // Determine if the student is in a senior class
  const isSeniorClass = (Class === "الثاني عشر" || Class === "الحادي عشر" || Class === "العاشر");

  // Conditional logic for the school logo and name
  const schoolLogo = isSeniorClass ? almaaleeSchoolLogo : almomayzonSchoolLogo;
  const schoolName = isSeniorClass ? "مدرسة المعالي الخاصة" : "مدرسة المميزون الخاصة";
  const schoolPhoneNumber= isSeniorClass ? "0945707041" : "0954158690";
  // Conditional style for header, footer, and content-row border
  const headerFooterStyle = {
    backgroundColor: isSeniorClass ? '#0B3857' : '#990b25' // Blue for seniors, red for others
  };

  const contentRowStyle = {
    border: isSeniorClass ? 'none' : '2px solid #990b25', // Red border for non-seniors
    position: 'relative', // Allow footer to be positioned within content-row
    paddingBottom: '50px' // Make space for the footer at the bottom
  };

  return (
    <div className="id-card-container">
      {/* Front Side */}
      <div className="id-card">
        <div className="header" style={headerFooterStyle}>
          <div className="logo">
            <img src={schoolLogo} alt="School Logo" />
          </div>
          <div className="school-info">
            <h1>{schoolName}</h1>
            <p className="id-card-text">بطاقة الطالب</p>
          </div>
        </div>

        <div className="content-row" style={contentRowStyle}>
          <div className="profile">
            <img src={StudentImage || schoolLogo} alt="Student Photo" />
          </div>

          <div className="student-info">
            <p><strong>اسم الطالب:</strong> {Name}</p>
            <p><strong>رقم الطالب:</strong> {StudentNum}</p>
            <p><strong>صف الطالب:</strong> {Class}</p>
            <p><strong>الهاتف:</strong> {Phone}</p>
            <p><strong>العنوان:</strong> {Address}</p>
          </div>

          {/* Footer positioned at the bottom of content-row */}
          <div className="footer" style={{ ...headerFooterStyle, position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)', width: '95%' }}>
            <p>
              <a href="http://www.almaalee.com">www.almaalee.com</a> <strong>{schoolPhoneNumber} الاتصال بالمدرسة  </strong>
            </p>
          </div>
        </div>
      </div>

      {/* Back Side */}
      <div className="id-card back">
        <img src={qrCode} style={{width: '300px', height: '300px', objectFit: 'contain'}} alt="QR Code"/>

      </div>
    </div>
  );
};

export default IDCard;
