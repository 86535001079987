// src/components/ControlPage.js
import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, List, ListItem, ListItemText, Paper, TextField, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ControlPage = ({BASE_URL}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(''); // To store the name of the selected image
  const [contentItems, setContentItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For displaying the image preview
  const [urgent, setUrgent] = useState(false);

  useEffect(() => {
    return () => {
      // Cleanup the image preview URL
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);
  useEffect(() => {
    // Fetch existing content items
    const fetchContentItems = async () => {
      try {
        const response = await fetch(`${BASE_URL}api/news`);
        const data = await response.json();

        // Convert byte arrays to base64 strings for displaying images
        const processedNews = data.map(newsItem => {
          let imageUrl = null;
          if (newsItem.image) {
            const binaryString = Array.from(new Uint8Array(newsItem.image)).map(byte => String.fromCharCode(byte)).join('');
            imageUrl = `data:image/jpeg;base64,${btoa(binaryString)}`;
          }

          return {
            ...newsItem,
            image: imageUrl,
          };
        });

        setContentItems(processedNews);
      } catch (err) {
        console.error("Error fetching content:", err);
      }
    };

    fetchContentItems();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append('urgent', urgent); // Add urgent to the form data
    if (image) {
      formData.append("image", image);
    }

    try {
      const url = `${BASE_URL}api/news/${editingItem ? 'update/' + editingItem.id : 'add'}`;
      const method = editingItem ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        body: formData,
      });

      if (response.ok) {
        const newsItem = await response.json();
        alert("News added/updated successfully!");
        setTitle('');
        setContent('');
        setImage(null);
        setImageName('');
        setImagePreview(null);
        setEditingItem(null);

        // Refresh content items
        const updatedItems = await fetch(`${BASE_URL}api/news`).then(res => res.json());
        setContentItems(updatedItems);
      } else {
        alert("Failed to add/update news. Please try again.");
      }
    } catch (err) {
      alert("Error occurred while adding/updating news.");
    }
  };

  const handleDeleteContent = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}api/news/delete/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        alert("News deleted successfully!");
        setContentItems(prevItems => prevItems.filter(item => item.id !== id));
      } else {
        console.error("Failed to delete news");
        alert("Failed to delete news. Please try again.");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("Error occurred while deleting news.");
    }
  };

  const startEditing = (item) => {
    setTitle(item.title);
    setContent(item.content);
    setUrgent(item.urgent);
    setEditingItem(item);
    setImageName(item.imageName || ''); // Set the name of the image if editing
  };

  const handleImageChange = (e) => {
    const file = e.target.files[ 0 ];
    setImage(file);
    setImageName(file.name); // Set the name of the selected image
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Form Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom align="center">
              {editingItem ? 'تحديث الأخبار' : 'إضافة خبر'}
            </Typography>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                label="عنوان"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="محتوى"
                variant="outlined"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
                multiline
                rows={4}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button variant="contained" component="label" sx={{ mb: 2 }}>
                رفع الصورة
                <input type="file" hidden onChange={handleImageChange} />
              </Button>
              {imageName && (
                <>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    تم اختيار: {imageName}
                  </Typography>
                  {imagePreview && (
                    <Box sx={{ mb: 2 }}>
                      <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                    </Box>
                  )}
                </>
              )}
              <Box sx={{ mb: 2 }}>
                <label>
                  <input
                    type="checkbox"
                    checked={urgent}
                    onChange={(e) => setUrgent(e.target.checked)}
                  />
                  <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }} component="span">
                    خبر عاجل
                  </Typography>
                </label>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ padding: 1.5 }}
              >
                {editingItem ? 'تحديث' : 'إضافة'}
              </Button>
            </form>
          </Paper>
        </Grid>

        {/* List Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4, maxHeight: '80vh', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom align="center">
              قائمة الأخبار
            </Typography>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <List>
                {contentItems.map((item) => (
                  <ListItem key={item.id} sx={{ mb: 2 }} alignItems="flex-start">
                    {item.image && (
                      <img
                        src={item.image}
                        alt="news"
                        style={{ width: 80, height: 80, objectFit: 'cover', marginRight: 16 }}
                      />
                    )}
                    <ListItemText
                      primary={`${item.title}${item.urgent ? ' (عاجل)' : ''}`}
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            {item.content}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {new Date(item.createdAt).toLocaleDateString('ar-AR')}
                          </Typography>
                        </>
                      }
                    />
                    <IconButton edge="end" aria-label="edit" onClick={() => startEditing(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteContent(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ControlPage;
