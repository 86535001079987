import React, {useMemo, useState} from 'react';
import swal from "sweetalert";
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {Box, FormControl} from '@mui/material';
import Navbar from "./navBar/Navbar";
import Button from "@material-ui/core/Button";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {IconButton} from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {download, generateCsv, mkConfig} from 'export-to-csv';
import HomePage from "../index/HomePage";
import {studentClassOptions} from "../utils/studentClassOptions";
import {bloodTypeOptions} from "../utils/BloodType";
import {Delete, Edit, Info} from "@material-ui/icons";
import StudentRegistrationPayment from "../student/register/StudentRegistrationForm";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import ControlDialog from "../student/ControlDialog";
import ClassSelector from "./ClassSelector";
import { BASE_URL }  from "../utils/BaseUrl"
import CSVUpload from './CSVUpload';

const RegisterStudentV2 = () => {
   const [tableData, setTableData] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openStudentInfoDialog, setOpenStudentInfoDialog] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);

  const closeStudentInfoDialog = () => {
    setSelectedRow(null);
    setOpenStudentInfoDialog(false);
  }

  const handleOpenStudentInfoDialog = (id) => {
    setSelectedRow(id);
    setOpenStudentInfoDialog(true)
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'الاسم',
        size: 150,
      },
      {
        accessorKey: 'studentAddress',
        header: 'عنوان المنزل',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'yearOfRegistration',
        header: 'سنة الانتساب للمدرسة',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'studentClass',
        header: 'الصف',
        size: 150,
        editVariant: 'select',
        enableEditing: true,
        editSelectOptions: studentClassOptions,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'placeDateBirth',
        header: 'مكان الميلاد والتاريخ',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'bloodType',
        header: 'زمرة الدم',
        size: 150,
        editVariant: 'select',
        enableEditing: true,
        editSelectOptions: bloodTypeOptions,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'fatherPhoneNumber',
        header: 'رقم هاتف الأب',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'motherPhoneNumber',
        header: 'رقم هاتف الأم',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'email',
        header: 'بريد إلكتروني',
        size: 150,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
      {
        accessorKey: 'id',
        header: 'ID',
        size: 150,
        enableEditing: false,
        columnVisibility: {mobile: false}, // Hide on mobile
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ';',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(tableData);
    download(csvConfig)(csv);
  };
  const getButtonClick = async () => {
    const apiUrl = BASE_URL + 'getAllStudents';
    if (selectedClass.length === 0) {
      await swal("Error", "Please pick a class to get data.");
      return;
    }
    // Convert query parameters to a string
    try {
      let params = new URLSearchParams();
      params.append('studentClass', selectedClass);
      const paramString = params.toString()
      const fullUrl = `${apiUrl}?${paramString}`;
      const response = await fetch(fullUrl);
      if (!response) {
        return;
      }
      if (response.status === 200) {
        const data = await response.json()

        setTableData(data)
      } else if (response.status === 404) {
        await swal("Failed", "Not found");
      }
    } catch (err) {
      await swal(err);
    }
  }

  async function deleteStudent (id) {
    const apiUrl = BASE_URL + 'deleteStudent';
    try {
      let params = new URLSearchParams();
      params.append('id', id);
      const paramString = params.toString()
      const fullUrl = `${apiUrl}?${paramString}`;
      const response = await fetch(fullUrl);

      if (response.status !== 200) {
        await swal("Failed", "Not found");
      }
    } catch (err) {
      await swal(err);
    }
  }

  function openDeleteConfirmModal (id) {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteStudent(id);
    }
  }

  async function handleSaveClick (values) {
    const apiUrl = BASE_URL + 'updateStudentInfo';

    // Convert query parameters to a string
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({
          id: values.id,
          name: values.name,
          email: values.email,
          studentClass: values.studentClass,
          placeDateBirth: values.placeDateBirth,
          bloodType: values.bloodType,
          fatherPhone: values.fatherPhoneNumber,
          motherPhone: values.motherPhoneNumber,
          studentAddress: values.studentAddress,
          yearOfRegistration: values.yearOfRegistration
        })
      })
      if (response.ok) {
        swal("Success")
      } else {
        await swal("Failed", "error");
      }
    } catch (err) {
      await swal(err);
    }
  }

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    layoutMode: 'grid',
    enableRowActions: true,
    columnResizeDirection: 'rtl',
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
      },
    },
    initialState: {
      showGlobalFilter: true,
      columnVisibility: {
        name: true, // Show name by default
        'mrt-row-actions': true, // Show actions by default
        // Set other columns as needed, hiding them on mobile
        studentAddress: true,
        yearOfRegistration: true,
        studentClass: true,
        placeDateBirth: true,
        bloodType: true,
        fatherPhoneNumber: true,
        motherPhoneNumber: true,
        email: true,
        id: true,
      },
    },
    positionGlobalFilter: 'left',
    editDisplayMode: 'modal',
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
        '@media (max-width: 600px)': {
          maxHeight: '400px',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[ 900 ] : theme.palette.grey[ 50 ],
        padding: '12px 8px',
        '@media (max-width: 600px)': {
          padding: '8px 4px',
          fontSize: '12px',
        },
      }),
    },
    muiSearchTextFieldProps: {
      placeholder: `Search ${tableData.length} rows`,
      sx: {
        minWidth: '300px',
        '@media (max-width: 600px)': {
          minWidth: '100%',
          fontSize: '14px',
        },
      },
      variant: 'outlined',
    },
    onEditingRowSave: ({table, values}) => {
      handleSaveClick(values); // Save the row
      table.setEditingRow(null); // Exit editing mode
    },
    enableExpandAll: false, // Disable expand all for simpler mobile view
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 100,
        grow: false,
      },
    },
    renderRowActions: ({row, table}) => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          justifyContent: 'center',
          '@media (max-width: 600px)': {
            flexDirection: 'row', // Show buttons inline for mobile
          },
        }}
      >
        <IconButton
          color="secondary"
          onClick={() => handleOpenStudentInfoDialog(row.original)}
        >
          <Info/>
        </IconButton>
        <IconButton
          color="primary"
          onClick={() => table.setEditingRow(row)}
        >
          <Edit/>
        </IconButton>
        <IconButton
          color="secondary"
          onClick={() => {
            tableData.splice(row.index, 1); // Delete row
            setTableData([...tableData]);
            openDeleteConfirmModal(row.original.id);
          }}
        >
          <Delete/>
        </IconButton>
      </Box>
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255,210,244,0.1)' : 'rgba(0,0,0,0.1)',
      }),
    }),
    muiExpandButtonProps: ({row, table}) => ({
      onClick: () => table.setExpanded({[ row.id ]: !row.getIsExpanded()}),
      sx: {
        transform: row.getIsExpanded() ? 'rotate(90deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s',
        '@media (max-width: 600px)': {
          transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        },
      },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap'}}>
        {/* Add any top toolbar buttons like "Add New Student" here */}
      </Box>
    ),
    enableColumnVisibilityChange: true, // Allow toggling column visibility
    onColumnVisibilityChange: (updatedVisibility) => {
      if (window.innerWidth <= 600) {
        // On mobile, only show the name and actions
        return {name: true, 'mrt-row-actions': true}; // Only these columns visible on mobile
      }
      return updatedVisibility; // Default visibility for larger screens
    },
  });

  const token = localStorage.getItem('accessToken');
  const admin = localStorage.getItem('admin');

  if (!token || !admin) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id');
    localStorage.removeItem('admin');
    return <HomePage/>
  }

  const handleSaveRow = async ({exitEditingMode, row, values}) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    tableData[ row.index ] = values;
    //send/receive api updates here
    setTableData([...tableData]);

    exitEditingMode(); //required to exit editing mode
  };
  const theme = createTheme();




  return (
    <div>
      <ThemeProvider theme={theme}>
        <Navbar/>
      </ThemeProvider>
      <div style={{textAlign: 'center', padding: '20px'}}>
        <Box>
          <FormControl sx={{m: 1, width: {xs: '100%', sm: '80%', md: '300px'}}}>
            <ClassSelector setSelectedClass={setSelectedClass} fullWidth/>
            <br/>
            <Button
              type="submit"
              size="large"
              color="primary"
              endIcon={<SendIcon/>}
              variant="contained"
              onClick={getButtonClick}
              sx={{width: {xs: '100%', sm: '80%', md: 'auto'}, mb: 2}}
            >
              عرض
            </Button>
            <br/>
            <Button
              type="submit"
              size="large"
              color="primary"
              endIcon={<PersonAddAltIcon/>}
              variant="contained"
              onClick={handleClickOpen}
              sx={{width: {xs: '100%', sm: '80%', md: 'auto'}, mb: 2}}
            >
              تسجيل تلميذ
            </Button>
            <br/>
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon/>}
              sx={{width: {xs: '100%', sm: '80%', md: 'auto'}}}
            >
              تصدير جميع البيانات
            </Button>
            <br/>
            {/* Add CSV Upload Button */}
            <CSVUpload />
          </FormControl>
        </Box>
        <ThemeProvider theme={theme}>
          <StudentRegistrationPayment open={open} close={handleClickClose} BASE_URL={BASE_URL}/>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <ControlDialog
            isOpen={openStudentInfoDialog}
            onClose={closeStudentInfoDialog}
            admin={admin}
            selectedRow={selectedRow}
            BASE_URL={BASE_URL}
          />
        </ThemeProvider>
      </div>
      <div style={{padding: '10px'}}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <MaterialReactTable
          enableRowVirtualization
          enableBottomToolbar={false}
          enablePagination={false}
          enableEditing
          onEditingRowSave={handleSaveRow}
          table={table}
        />
      </div>
    </div>
  );
};

export default RegisterStudentV2;
