import React from 'react';
import { Select, MenuItem } from '@mui/material'; // Adjust import according to your UI library

function ClassSelector({ style, setSelectedClass }) {
  // Get classes from local storage
  const classes = JSON.parse(localStorage.getItem('classes')) || {};

  // Define the correct order for sorting
  const classOrder = [
    'الأول',
    'الثاني',
    'الثالث',
    'الرابع',
    'الخامس',
    'السادس',
    'السابع',
    'الثامن',
    'التاسع',
    'العاشر',
    'الحادي عشر',
    'الثاني عشر',
  ];

  // Function to sort the classes based on the predefined order
  const sortedClasses = Object.keys(classes).sort((a, b) => {
    return classOrder.indexOf(a) - classOrder.indexOf(b);
  });

  return (
    <Select
      style={style}
      labelId="class-select-label"
      id="class-select"
      defaultValue={"NA"}
      label="الصف"
      onChange={(e) => setSelectedClass(e.target.value)}
    >
      {/* Render sorted class names */}
      {sortedClasses.map((className) => (
        <MenuItem key={className} value={className} dir="rtl">
          {className}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ClassSelector;
