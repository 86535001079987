import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Navbar from '../admin/navBar/Navbar';
import ControlPage from '../content/news/ControlPage';
import GalleryUpload from "./Gallery/GalleryUpload";
import GalleryDisplay from "./Gallery/GalleryDisplay";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Container} from "@mui/system";
import {Box, Grid} from "@mui/material";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});


export default function SiteContentControl () {

  const BASE_URL = 'https://school-backend-8affd52fc0dc.herokuapp.com/';
  //const BASE_URL = 'http://localhost:8080/';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <Container maxWidth="lg">
        <Box my={4}>
          <Grid container spacing={4} direction="column">
            {/* ControlPage Section */}
            <Grid item xs={12} md={4}>
              <Box>
                <ControlPage BASE_URL={BASE_URL} />
              </Box>
            </Grid>

            {/* Gallery Section */}
            <Grid item xs={12} md={8} container spacing={4} direction="column">
              <Grid item>
                <Box>
                  <GalleryUpload BASE_URL={BASE_URL} />
                </Box>
              </Grid>
              <Grid item flexGrow={1} sx={{ overflowY: 'auto' }}>
                <Box>
                  <GalleryDisplay BASE_URL={BASE_URL} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
