import React, {useEffect, useState} from 'react';
import {BASE_URL} from '../utils/BaseUrl';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {InputLabel} from "@material-ui/core";
import {Container} from "@mui/system";
import {jssPreset} from '@mui/styles';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Navbar from "./navBar/Navbar";

// JSS setup for RTL
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

// MUI Theme with RTL support
const theme = createTheme({

  typography: {
    fontFamily: `"Cairo", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

class SchoolSubject {
  constructor (name, maxGrade, minGrade) {
    this.name = name;
    this.maxGrade = maxGrade;
    this.minGrade = minGrade;
  }
}

// Define subjects for various classes
const secondaryTenthSubjects = [
  new SchoolSubject('هندسة', 200, 80),
  new SchoolSubject('جبر', 200, 80),
  new SchoolSubject('علم احياء', 200, 80),
  new SchoolSubject('فيزياء', 200, 80),
  new SchoolSubject('كيمياء', 200, 80),
  new SchoolSubject('لغة عربية', 500, 200),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 400, 160),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('تربية وطنية', 200, 80),
  new SchoolSubject('جغرافيا', 200, 80),
  new SchoolSubject('تاريخ', 200, 80),
  new SchoolSubject('فلسفة', 200, 80),
  new SchoolSubject('اثراء', 200, 80),
];

const secondarySubjects = [
  new SchoolSubject('هندسة', 300, 120),
  new SchoolSubject('جبر', 300, 120),
  new SchoolSubject('علم احياء', 300, 120),
  new SchoolSubject('فيزياء', 400, 120),
  new SchoolSubject('كيمياء', 200, 80),
  new SchoolSubject('لغة عربية', 400, 160),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 300, 120),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('تربية وطنية', 200, 80),
];

const elementarySubjects = [
  new SchoolSubject('رياضيات', 600, 240),
  new SchoolSubject('لغة عربية', 600, 300),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 400, 160),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('اجتماعيات', 600, 240),
  new SchoolSubject('اثراء', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('فيزياء وكيماء', 200, 80),
  new SchoolSubject('علوم', 200, 80),
];

// Create a mapping of classes to their subjects
const subjectsByClass = {
  'الأول': elementarySubjects,
  'الثاني': elementarySubjects,
  'الثالث': elementarySubjects,
  'الرابع': elementarySubjects,
  'الخامس': elementarySubjects,
  'السادس': elementarySubjects,
  'السابع': elementarySubjects,
  'الثامن': elementarySubjects,
  'التاسع': elementarySubjects,
  'العاشر': secondaryTenthSubjects,
  'الحادي عشر': secondarySubjects,
  'الثاني عشر': secondarySubjects,
};

const StudentsExamsResults = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExamInfo, setLoadingExamInfo] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [studentExamInfo, setStudentExamInfo] = useState([]);
  const [grades, setGrades] = useState({});
  const examTypes = ["شفهي", "وظائف واوراق عمل", "نشاط ومبادرات", "مذاكرة", "الامتحان"];

  const semesters = ['الأول', 'الثاني'];
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
// Fetch students when selected class changes
  useEffect(() => {
    if (selectedClass) {
      loadStudents();
    }
  }, [selectedClass]);
  useEffect(() => {
    if (selectedSubject) {
      // Load the new exam info only when the subject changes
      loadStudentsExamInfo();
    }
  }, [selectedSubject]); // Run this effect only when selectedSubject changes
  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter the students based on the search term (case-insensitive)
  const filteredStudents = students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Load students based on selected class
  const loadStudents = async () => {
    console.log(selectedClass);
    setStudents([]);
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}getAllStudents?studentClass=${selectedClass}`);
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      console.error("Error fetching students:", error);
    } finally {
      setLoading(false);
    }
  };

  // Load exam information based on selected class and semester
  const loadStudentsExamInfo = async () => {
    if (!selectedClass || !selectedSemester || !selectedSubject) {
      return; // Ensure all necessary filters are selected
    }

    setLoadingExamInfo(true);
    try {
      const response = await fetch(`${BASE_URL}getStudentsExamInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: students.map((student) => student.id),
          semester: selectedSemester, // Ensure we are filtering by semester
          subject: selectedSubject,   // Filter by selected subject
        }),
      });

      const data = await response.json();
      console.log('Response Data:', data); // Debugging: Check API response

      if (response.ok && data.length > 0) {
        const initialGrades = {};

        // Iterate over the response and map grades by student and exam type
        data.forEach((exam) => {
          const {studentId, subject, results} = exam; // Destructure the response object

          // Only process data for the selected subject
          if (subject === selectedSubject) {
            // Ensure the grade entry for the student exists
            if (!initialGrades[ studentId ]) {
              initialGrades[ studentId ] = {};
            }

            // Assign the results (grades) for each exam type to the student
            Object.keys(results).forEach((examType) => {
              initialGrades[ studentId ][ examType ] = results[ examType ];
            });
          }
        });

        console.log('Processed Grades:', initialGrades); // Debugging: Check processed grades

        // Update state with the processed grades
        setGrades(initialGrades);
        setStudentExamInfo(data);
      } else {
        console.error('Error or no data:', data); // Handle if no data is returned
        setGrades({}); // Clear grades if no data
      }
    } catch (error) {
      console.error('Error fetching exam info:', error);
    } finally {
      setLoadingExamInfo(false);
    }
  };

  // Set subjects based on selected class
  const loadSubjectsForClass = (selectedClass) => {
    const subjects = subjectsByClass[ selectedClass ] || [];
    setSubjects(subjects);
    setSelectedSubject(''); // Reset selected subject when class changes
    setStudentExamInfo([]); // Reset exam info on class change
    setGrades({}); // Reset grades state
  };

  // Load students when selectedClass changes

// Handle grade change
  const handleGradeChange = (studentId, examType, value) => {
    // Optionally, reset the grades for the specific student and exam type
    setGrades((prevGrades) => ({
      ...prevGrades,
      [ studentId ]: {
        ...prevGrades[ studentId ],
        [ examType ]: value, // Update the specific grade
      },
    }));
  };

  const handleSaveAll = async () => {
    try {
      // Prepare an array to hold all student exam info
      const studentExams = [];

      // Loop through each student's grades
      Object.keys(grades).forEach((studentId) => {
        const studentGrades = grades[ studentId ];

        // For each exam type, create an exam record
        examTypes.forEach((examType) => {
          const grade = studentGrades[ examType ]; // Get the grade for the specific exam type

          // Only push records with non-empty grades
          if (grade) {
            studentExams.push({
              id: studentId, // Student ID
              subject: selectedSubject, // The selected subject
              grade: grade, // The grade for this exam type
              type: examType, // The type of the exam (e.g., شفهي, وظائف واوراق عمل)
              semester: selectedSemester, // The selected semester
            });
          }
        });
      });

      // Prepare the request body
      const requestBody = {
        studentExams: studentExams, // List of individual exam records
      };

      console.log('Request Body:', requestBody); // Debugging: Check what we're sending

      // Send the data to the backend
      const response = await fetch(`${BASE_URL}saveStudentsExamInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody), // Convert the request body to JSON
      });

      if (response.ok) {
        console.log('All grades saved successfully.');
        alert('تم حفظ جميع الدرجات بنجاح');
      } else {
        console.error('Failed to save grades:', response.statusText);
        alert('فشل في حفظ الدرجات');
      }
    } catch (error) {
      console.error('Error occurred while saving grades:', error);
      alert('حدث خطأ أثناء حفظ الدرجات');
    }
  };

  const handleSave = async (studentId) => {
    // Get the grades for the specific student
    const studentGrades = grades[ studentId ] || {};

    // Create an array of StudentExam objects
    const studentExamData = Object.keys(studentGrades).map((examType) => ({
      id: studentId, // This should match the structure expected by your backend
      subject: selectedSubject, // Assuming you are saving grades for the selected subject
      grade: studentGrades[ examType ], // The grade entered by the user
      type: examType, // The exam type, e.g., "وظائف واوراق عمل"
      semester: selectedSemester // Include the semester if needed
    }));

    try {
      const response = await fetch(`${BASE_URL}saveStudentExamInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studentExamData), // Send the array of StudentExam objects
      });

      if (response.ok) {
        // Handle successful response (optional: show a success message)
        alert('تم حفظ درجات الطالب بنجاح!');
      } else {
        // Handle errors from the backend
        const errorData = await response.json();
        console.error('Error saving exam info:', errorData);
        alert('حدث خطأ أثناء حفظ الدرجات.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('حدث خطأ في الشبكة.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{backgroundColor: '#f4f6f8', minHeight: '100vh', direction: "rtl"}}>
        <Navbar/>
        <Container maxWidth="md" sx={{py: 4}}>
          {/* Title */}
          <Typography variant="h4" gutterBottom align="center" dir={"rtl"}>
            نتائج امتحانات الطلاب
          </Typography>
          <Grid container spacing={2} direction="row">
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>الفصل</InputLabel>
                <Select
                  value={selectedSemester}
                  onChange={(e) => setSelectedSemester(e.target.value)}
                  label="الفصل"
                >
                  {semesters.map((semester, index) => (
                    <MenuItem key={index} value={semester} dir="rtl">
                      {semester}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>الصف</InputLabel>
                <Select
                  value={selectedClass}
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                    loadSubjectsForClass(e.target.value);
                  }}
                  label="الصف"
                >
                  <MenuItem value="">اختر الصف</MenuItem>
                  {Object.keys(subjectsByClass).map((classOption, index) => (
                    <MenuItem key={index} value={classOption} dir="rtl">
                      {classOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>المادة</InputLabel>
                <Select
                  value={selectedSubject}
                  onChange={(e) => {
                    setSelectedSubject(e.target.value); // This will trigger the useEffect above
                    setGrades({}); // Clear grades before loading new ones
                    setStudentExamInfo([]); // Clear previous exam info
                  }}
                  label="المادة"
                >
                  <MenuItem value="">اختر المادة</MenuItem>
                  {subjects.map((subject, index) => (
                    <MenuItem key={index} value={subject.name}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>

            </Grid>
          </Grid>
          <Box mt={4}>
            {loading && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress/>
                <Typography sx={{ml: 2}}>جاري تحميل الطلاب...</Typography>
              </Box>
            )}
            {loadingExamInfo && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress/>
                <Typography sx={{ml: 2}}>جاري تحميل درجات الامتحانات...</Typography>
              </Box>
            )}
            {students.length > 0 && selectedSubject && (
              <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                  نتائج الطلاب في الصف {selectedClass} لمادة {selectedSubject}:
                </Typography>
                <Box mt={4} dir="rtl">
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveAll()}
                  >
                    حفظ الكل
                  </Button>
                  <TextField
                    dir={"rtl"}
                    label="ابحث عن الطالب"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="بحث..."
                    margin="normal"
                  />
                </Box>
                <Grid item xs={12}> {/* Use full width on larger screens */}
                  <TableContainer component={Paper} direction="rtl" style={{maxHeight: 600, overflow: 'auto'}}>
                    <Table stickyHeader>
                      {/* Table Headers */}
                      <TableHead>
                        <TableRow>
                          {/* Student Name Header */}
                          <TableCell
                            sx={{backgroundColor: 'primary.main', color: 'white', position: 'sticky', top: 0}}>
                            اسم الطالب
                          </TableCell>

                          {/* Dynamic Exam Type Headers */}
                          {examTypes.map((examType) => (
                            <TableCell
                              key={examType}
                              sx={{backgroundColor: 'primary.main', color: 'white', position: 'sticky', top: 0}}
                            >
                              {examType}
                            </TableCell>
                          ))}

                          {/* Save Button Column Header */}
                          <TableCell
                            sx={{backgroundColor: 'primary.main', color: 'white', position: 'sticky', top: 0}}>
                            عمليات
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody>
                        {filteredStudents.map((student) => {
                          const studentGrades = grades[ student.id ] || {}; // Get grades for each student or fallback to an empty object

                          return (
                            <TableRow key={student.id}>
                              {/* Student Name Cell */}
                              <TableCell>{student.name}</TableCell>

                              {/* Exam Types and Grades */}
                              {examTypes.map((examType) => (
                                <TableCell key={examType}>
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{min: 0, max: 100}}
                                    value={studentGrades[ examType ] !== undefined ? studentGrades[ examType ] : ''} // Show grade or empty if undefined
                                    onChange={(e) => handleGradeChange(student.id, examType, e.target.value)}
                                    placeholder="الدرجة"
                                    fullWidth
                                  />
                                </TableCell>
                              ))}

                              {/* Save Button for Each Student */}
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleSave(student.id)}
                                >
                                  حفظ
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Box>
            )}
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default StudentsExamsResults;
