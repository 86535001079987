import React, {useEffect, useState} from "react";
import HomePage from "../index/HomePage";
import Navbar from "./navBar/Navbar";
import {Grid, Paper, Typography} from "@mui/material";
import AdminRules from "./adminRules";
import axios from "axios";
import CreateAdminForm from "./CreateAdminForm";
import AdminTable from "./AdminTable";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Divider} from "antd";
import {Container} from "@mui/system";
import { BASE_URL }  from "../utils/BaseUrl"
const AdminDashboard = (props) => {
  const [users, setUsers] = useState([])


  const [admins, setAdmins] = useState([]);
  useEffect(() => {

    fetch(BASE_URL + "getAllAdmins")
      .then(response => response.json())
      .then(json => setUsers(json))
  }, [])
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [supervisorAdmin, setSupervisorAdmin] = useState(false);
  const [classes, setClasses] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const token = localStorage.getItem('accessToken');
  const supervisorAdminPermission = localStorage.getItem('supervisorAdmin');
  const admin = localStorage.getItem('admin');

  if (!token || !admin || !supervisorAdminPermission) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('id');
    localStorage.removeItem('admin')
    localStorage.removeItem('supervisorAdmin');
    return <HomePage/>
  }

  const handleSave = () => {
    // Call the saveUserDetails function and pass the updated user details
    saveUserDetails({
      ...selectedUser,
      supervisorAdmin,
      classes,
    });

    setOpen(false); // Close the dialog after saving
  };
  const saveUserDetails = async (updatedUser) => {
    try {
      // Make a PUT request to your backend to save the updated user details
      await axios.post(BASE_URL + 'updateAdminInfo', updatedUser);

      // Update the user in the local state
      setUsers(prevUsers =>
        prevUsers.map(user =>
          user.id === updatedUser.id ? updatedUser : user
        )
      );
    } catch (error) {
      console.error('Failed to save user details:', error);
    }
  };
  const handleAdminCreated = (newAdmin) => {
    // Add the newly created admin to the list
    setAdmins([...admins, newAdmin]);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#ff5722',
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div style={{backgroundColor: '#f4f6f8', minHeight: '100vh'}}>
        <Navbar/>
        <Container maxWidth="lg" style={{marginTop: '40px'}}>
          <Grid container spacing={4} dir="rtl">
            {/* Create Admin Section */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{padding: '20px', height: '100%'}}>
                <Typography variant="h4" gutterBottom align="center" style={{color: theme.palette.primary.main}}>
                  إنشاء مسؤول جديد
                </Typography>
                <Divider style={{marginBottom: '20px'}}/>
                <CreateAdminForm onAdminCreated={handleAdminCreated} BASE_URL={BASE_URL}/>
              </Paper>
            </Grid>

            {/* Admin Table and Rules Section */}
            <Grid item xs={12}>
              <Paper elevation={3} style={{padding: '20px', height: '100%', maxHeight: '80vh', overflowY: 'auto'}}>
                <Typography variant="h4" gutterBottom align="center" style={{color: theme.palette.primary.main}}>
                  قائمة المسؤولين
                </Typography>
                <Divider style={{marginBottom: '20px'}}/>
                <AdminTable URL={BASE_URL}/>
                {selectedUser && (
                  <AdminRules
                    open={open}
                    handleClose={handleClose}
                    handleSave={handleSave}
                    supervisorAdmin={supervisorAdmin}
                    setSupervisorAdmin={setSupervisorAdmin}
                    classes={classes}
                    setClasses={setClasses}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );

}
export default AdminDashboard