import {BrowserRouter, Route, Routes} from 'react-router-dom'
import './App.css'
import Admin from "./admin/admin";
import AdminDashboard from "./admin/AdminDashboard";
import HomePage from "./index/HomePage";
import RegisterStudentV2 from "./admin/StudentInfoUpdatedVersion";
import SiteContentControl from "./content/SiteContentControl";
import AboutPage from "./index/AboutPage";
import YearlyPaymentsTable from "./admin/YearlyPaymentsTable";
import GenerateStudentsCards from "./admin/GenerateStudentsCards";
import StudentsExamResults from "./admin/StudentsExamsResults";
import StudentWeeklyExam from "./admin/StudentWeeklyExam";

function App() {
    const token = localStorage.getItem('accessToken');
    if(!token) {
        return <HomePage />
    }

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/adminDashboard" element={<AdminDashboard/>}/>
                    <Route path="/studentInfoAll" element={<RegisterStudentV2/>}/>
                    <Route path="/siteContentControl" element={<SiteContentControl />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/yearlypayments" element={<YearlyPaymentsTable />} />
                    <Route path="/generateStudentsCards" element={<GenerateStudentsCards />} />
                    <Route path="/studentsExamResults" element={<StudentsExamResults />} />
                    <Route path="/studentsWeeklyExamResults" element={<StudentWeeklyExam />} />
                </Routes>
            </BrowserRouter>

        </div>
    )
}

export default App