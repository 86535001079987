import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel } from '@mui/material';

const AdminRules = ({ open, handleClose, handleSave, supervisorAdmin, setSupervisorAdmin, classes, setClasses }) => {
  // Define an array for grades 1 through 12
  const grades = [
    "الأول",
    "الثاني",
    "الثالث",
    "الرابع",
    "الخامس",
    "السادس",
    "السابع",
    "الثامن",
    "التاسع",
    "العاشر",
    "الحادي عشر",
    "الثاني عشر"
  ];

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>User Details</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={supervisorAdmin}
              onChange={(e) => setSupervisorAdmin(e.target.checked)}
            />
          }
          label="Supervisor Admin"
        />
        <div>
          <h3>Classes</h3>
          {grades.map(grade => (
            <FormControlLabel
              key={grade}
              control={
                <Checkbox
                  checked={classes[grade] || false} // Default to false if undefined or null
                  onChange={(e) => {
                    setClasses(prevClasses => ({
                      ...prevClasses,
                      [grade]: e.target.checked,
                    }));
                  }}
                />
              }
              label={`${grade}`}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AdminRules;
