import React, {useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {AppBar, Box, Button, CardContent, CardMedia, CircularProgress, IconButton, Toolbar} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MainDrawer from "./MainDrawer";
import almaalee from "../assets/almaalee.jpg"
import NewsTicker from "../content/news/NewsTicker";
import NewsDialog from "./NewsDialog";
import {Card} from "antd";
import Gallery from "./Gallery";
import Footer from "../footer/footer";

import { BASE_URL }  from "../utils/BaseUrl"

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary color
    },
    secondary: {
      main: '#ff5722', // Secondary color
    },
  },
  typography: {
    h4: {
      fontWeight: 700,
    },
  },
});

export default function HomePage () {
  const [news, setNews] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null); // State for the selected news item
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(BASE_URL + "api/news");
        const data = await response.json();

        // Convert image data to base64 strings (if necessary)
        const processedNews = data.map(newsItem => {
          let imageUrl = newsItem.image; // Assume the backend sends Base64 string

          if (newsItem.image && !newsItem.image.startsWith('data:image/')) {
            // Fallback conversion if the backend still sends byte data instead of Base64
            const binaryString = Array.from(new Uint8Array(newsItem.image))
              .map(byte => String.fromCharCode(byte))
              .join('');
            imageUrl = `data:image/jpeg;base64,${btoa(binaryString)}`;
          }

          return {
            ...newsItem,
            image: imageUrl, // Use Base64 string directly
          };
        });

        // Sort news by date in descending order to show the newest first
        const sortedNews = processedNews.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setNews(sortedNews);
      } catch (err) {
        console.error("Error fetching news:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);
  const handleSidebarOpenToggle = () => {
    setSidebarOpen(true);
  };

  const handleClickOpen = (newsItem) => {
    setSelectedNews(newsItem);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Ensure full height of the viewport
        bgcolor: 'background.default',
        direction: 'rtl', // Right-to-left layout
      }}
    >
      <CssBaseline/>
      <AppBar
        position="static"
        elevation={3}
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 1,
        }}
      >
        <Toolbar
          sx={{
            position: 'relative', // Make the Toolbar a relative container
            display: 'flex',
            justifyContent: 'center', // Center-align the flex items
            alignItems: 'center',
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            sx={{
              position: 'absolute', // Position the button absolutely
              right: 16, // Position it to the right with some padding
              fontSize: '1.5rem',
              '&:hover': {
                bgcolor: 'primary.light',
              },
            }}
          >
            <MenuIcon/>
          </IconButton>

          <Typography
            variant="h5"
            sx={{
              position: 'absolute', // Position the Typography absolutely in the center
              left: 0,
              right: 0,
              textAlign: 'center',
              direction: 'rtl',
              fontWeight: 'bold',
              letterSpacing: '0.1rem',
            }}
          >
            مدرسة المعالي
          </Typography>

        </Toolbar>
      </AppBar>
           <MainDrawer
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        BASE_URL={BASE_URL}
      />
      {/* Main Content Area */}
      <Box sx={{p: 1}}>
        {/*<NewsTicker newsItems={news.filter(item => item.isUrgent)} BASE_URL={BASE_URL}/>*/}
      </Box>
      <Box sx={{flexGrow: 1, overflow: 'auto', p: 2}}>
        <Gallery BASE_URL={BASE_URL}/>
      </Box>

      {/* News Cards Section */}
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderTop: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          overflowX: 'auto',
        }}
      >
        {loading ? (
          <CircularProgress sx={{mx: 'auto'}}/>
        ) : (
          news.map((newsItem) => (
            <Box
              key={newsItem.id}
              sx={{
                mx: 1.5,
                cursor: 'pointer',
                display: 'inline-block',
                transition: 'transform 0.2s',
                '&:hover': {transform: 'scale(1.05)'},
              }}
              onClick={() => handleClickOpen(newsItem)}
            >
              <Card sx={{height: '100%'}}>
                <CardMedia
                  component="img"
                  height="160"
                  image={newsItem.image || almaalee}
                  alt={newsItem.title}
                  loading="lazy"
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{direction: 'rtl', fontWeight: 'bold'}}
                  >
                    {newsItem.title}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{mt: 2}}
                    fullWidth
                  >
                    قراءة المزيد
                  </Button>
                </CardContent>
              </Card>
            </Box>
          ))
        )}
      </Box>

      {/* Footer Component */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          boxShadow: 3,
          p: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Footer BASE_URL={BASE_URL}/>
      </Box>
      <NewsDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        selectedNews={selectedNews}
        almaalee={almaalee}
        sx={{direction: 'rtl'}}
      />
    </Box>

  );
};





