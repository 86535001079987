import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Grid } from '@mui/material';
import MessageList from './MessageList'; // Assuming MessageList is a component that displays messages
import SendMessageForm from './SendMessageForm'; // Assuming SendMessageForm is a component to send new messages
import swal from 'sweetalert';

const Chat = ({ admin,selectedRow, open, close,BASE_URL }) => {
    const [messages, setMessages] = useState([]);
    const [width, setWidth] = useState(1200);
    const [height, setHeight] = useState(1000);

    useEffect(() => {
        if (selectedRow && open) {
            handleGetMessage();
        }
    }, [selectedRow, open]);
    if (!selectedRow || !open) return null;
    async function handleGetMessage() {
        if (!selectedRow) return;
        const apiUrl = BASE_URL + 'getMessages';
        try {
            const params = new URLSearchParams({ id: selectedRow.id });
            const fullUrl = `${apiUrl}?${params.toString()}`;
            const response = await fetch(fullUrl);
            const data = await response.json();
            if (data && data.messages) {
                setMessages(data.messages);
            }
        } catch (err) {
            await swal('Error fetching messages:', err);
        }
    }

    const addMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    const handleResize = (e, direction) => {
        e.preventDefault();
        const startWidth = width;
        const startHeight = height;
        const startX = e.clientX;
        const startY = e.clientY;

        const doDrag = (dragEvent) => {
            if (direction === 'horizontal' || direction === 'both') {
                setWidth(startWidth + dragEvent.clientX - startX);
            }
            if (direction === 'vertical' || direction === 'both') {
                setHeight(startHeight + dragEvent.clientY - startY);
            }
        };

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag);
            document.removeEventListener('mouseup', stopDrag);
        };

        document.addEventListener('mousemove', doDrag);
        document.addEventListener('mouseup', stopDrag);
    };



    return (
        <Box>
            <Grid>
                <Dialog
                    open={open}
                    onClose={close}
                    fullWidth
                    maxWidth="sm"

                    PaperProps={{
                        component: 'form',
                        style: { width: `${width}px`, height: `${height}px`, resize: 'both', overflow: 'auto' },
                    }}
                >
          <span
              className="resizer"
              onMouseDown={(e) => handleResize(e, 'both')}
              style={{
                  cursor: 'se-resize',
                  display: 'inline-block',
                  width: '10px',
                  height: '10px',
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  backgroundColor: 'gray',
              }}
          ></span>
                    <DialogTitle  align="center" style={{ backgroundColor: '#f44336', color: '#fff' }}>ملاحظات الأهل - رسالة الإدارة</DialogTitle>
                    <DialogContent
                        className="chat-window"
                        dir="rtl"
                        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                    >
                        <div className="chat-window" dir="rtl">
                            <MessageList messages={messages} />
                            <SendMessageForm admin={admin} id={selectedRow.id} addMessage={addMessage} BASE_URL={BASE_URL}/>
                        </div>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Box>
    );
};

export default Chat;
