import React, { useState, useEffect } from 'react';
import { Box, Card, CardMedia, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import './Gallery.css'; // Import the CSS file

const Gallery = ({ BASE_URL }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${BASE_URL}api/gallery/images`);
        const data = await response.json();

        // Assuming data is a list of objects with base64 strings
        setImages(data.map(image => ({
          id: image.id,
          name: image.imageName,
          src: `data:image/jpeg;base64,${image.imageData}`
        })));
      } catch (error) {
        console.error('Error fetching gallery images:', error);
      }
    };

    fetchImages();
  }, [BASE_URL]);

  const handleClickOpen = (image) => {
    setSelectedImage(image.src);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box className="gallery-container">
        <Box className="gallery-wrapper">
          {images.map((image) => (
            <Card className="gallery-item" sx={{ position: 'relative', width: 200 }}>
              <CardMedia
                component="img"
                height="140"
                image={image.src}
                alt={image.name}
                sx={{ objectFit: 'cover' }}
                loading="lazy"
              />
              <IconButton
                sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }}
                onClick={() => handleClickOpen(image)}
              >
                <ZoomInIcon />
              </IconButton>
            </Card>
          ))}
        </Box>
      </Box>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: '100%', height: '100%' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Gallery;
