import {useState} from "react";
import swal from "sweetalert";

const SendMessageForm = ({admin, id, addMessage, BASE_URL}) => {
  const [message, setMessage] = useState('');
  const messageData = {
    id: id,
    text: String,
    source: String,
    date: new Date()
  };

  let updatedMessage
  if (admin) {
    updatedMessage = {...messageData, text: message, source: 'Management', date: new Date()};
  } else {
    updatedMessage = {...messageData, text: message, source: 'Family', date: new Date()};
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    try {
      await fetch(BASE_URL + 'sendMessage', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          id: updatedMessage.id,
          text: updatedMessage.text,
          source: updatedMessage.source,
          date: updatedMessage.date
        }),
      }).then(() => {

        addMessage(updatedMessage);
        setMessage('');
      }).catch(err => {
        swal("Failed to Send", "There was an error sending your message.", "error");
      })
    } catch (err) {
      await swal("Error", "There was an error sending your message.", "error");
    }
  };

  return (
    <form className="send-message-form">
            <textarea
              rows="3"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
      <button type="button" onClick={handleSubmit}>Send</button>
    </form>
  );
};

export default SendMessageForm;