import React, {useRef, useState} from "react";
import Navbar from "./navBar/Navbar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, FormControl} from "@mui/material";
import ClassSelector from "./ClassSelector";
import Button from "@material-ui/core/Button";
import SendIcon from "@mui/icons-material/Send";
import IDCard from "../student/StudentIDCard"; // Import your IDCard component
import swal from "sweetalert";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BASE_URL }  from "../utils/BaseUrl"
const GenerateStudentsCards = () => {


  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState({});
  const [studentImages, setStudentImages] = useState({});
  const [qrCodes, setQrCodes] = useState({});
  const studentCardRef = useRef();
  const theme = createTheme({
    palette: {
      primary: {main: "#1976d2"},
      secondary: {main: "#ff5722"},
    },
    typography: {fontFamily: "Arial, sans-serif"},
  });
// Store instances of ID cards in memory
  const saveIdCardsInMemory = async () => {
    const cardPromises = students.map(async (student) => {
      const cardElement = document.getElementById(`student-card-${student.id}`);
      if (cardElement) {
        // Capture the ID card
        const canvas = await html2canvas(cardElement, { scale: 2 });
        return canvas.toDataURL('image/png');
      } else {
        console.warn(`Card not found for student ID: ${student.id}`);
        return null;
      }
    });

    return Promise.all(cardPromises);
  };
  // Function to generate the PDF from saved cards
  const generatePdfFromCards = async () => {
    const cardImages = await saveIdCardsInMemory();

    const doc = new jsPDF();
    cardImages.forEach((imgData, index) => {
      if (imgData) {
        doc.addImage(imgData, 'PNG', 10, 10, 90, 0); // Adjust dimensions as necessary
        if (index < cardImages.length - 1) {
          doc.addPage(); // Add new page for each card
        }
      }
    });

    doc.save(selectedClass+'.pdf');
  };

  // Fetch students based on selected class
  const getButtonClick = async () => {
    const apiUrl = BASE_URL + "getAllStudents";
    if (!selectedClass) {
      await swal("Error", "Please pick a class to get data.");
      return;
    }
    try {
      let params = new URLSearchParams();
      params.append("studentClass", selectedClass);
      const fullUrl = `${apiUrl}?${params.toString()}`;
      const response = await fetch(fullUrl);
      if (response.ok) {
        const data = await response.json();
        setStudents(data);
        await fetchAllImagesAndQRCodes(data); // Fetch images and QR codes for all students
      } else {
        await swal("Failed", "Students not found.");
      }
    } catch (err) {
      await swal("Error", err.message);
    }
  };

  // Fetch all images and QR codes for the students

  const fetchAllImagesAndQRCodes = async (students) => {
    students.forEach(async (student) => {

      await fetchQr(student.id);
    });
  };

  // Fetch a student's QR code
  const fetchQr = async (id) => {
    try {
      const params = new URLSearchParams({id});
      const fullUrl = `${BASE_URL}generateStudentQRCodeLogin?${params.toString()}`;
      const response = await fetch(fullUrl);
      if (response.ok) {
        const qrBlob = await response.blob();
        const qrUrl = URL.createObjectURL(qrBlob);
        setQrCodes((prevQrCodes) => ({...prevQrCodes, [ id ]: qrUrl}));
      } else {
        await swal("Failed to get QR code.");
      }
    } catch (error) {
      console.error("Error fetching QR code:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: "#f4f6f8"}}>
        <Navbar />
      </div>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Box>
          <FormControl sx={{ m: 1, width: { xs: "100%", sm: "80%", md: "300px" } }}>
            <ClassSelector setSelectedClass={setSelectedClass} fullWidth />
            <br />
            <Button
              type="submit"
              size="large"
              color="primary"
              endIcon={<SendIcon />}
              variant="contained"
              onClick={getButtonClick}
              sx={{ width: { xs: "100%", sm: "80%", md: "auto" }, mb: 2 }}
            >
              اختر الفصل
            </Button>
            <Button
              type="button"
              size="large"
              color="primary"
              variant="contained"
              onClick={generatePdfFromCards}
              sx={{ width: { xs: "100%", sm: "80%", md: "auto" }, mb: 2 }}
            >
              تنزيل كافة بطاقات الطلاب
            </Button>
          </FormControl>
        </Box>
        <Box mt={2} textAlign="center" width="100%" padding={2}>
          {students.map((student) => (
            <Box
              key={student.id}
              id={`student-card-${student.id}`}  // Unique ID for each card
              sx={{ border: '1px solid #ccc', padding: 2, margin: 1, display: 'inline-block' }} // Styling for separation
            >
              <IDCard
                qrCode={qrCodes[student.id]}
                Name={student.name}
                Class={student.studentClass}
                Phone={student.fatherPhoneNumber || student.motherPhoneNumber}
                Address={student.studentAddress}
                StudentNum={student.studentNum}
                StudentImage={student.studentImage}
              />
            </Box>
          ))}
        </Box>
      </div>
    </ThemeProvider>
  );
};
export default GenerateStudentsCards;
