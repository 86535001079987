import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography, TextField } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const GalleryUpload = ({ BASE_URL }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    setMessage(''); // Clear any previous message
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      setMessage('يرجى اختيار صورة للتحميل');
      return;
    }
    setUploading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('image', selectedImage);

    try {
      const response = await fetch(`${BASE_URL}api/gallery/upload`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setMessage('تم تحميل الصورة بنجاح');
        setSelectedImage(null); // Clear selected image
      } else {
        const errorData = await response.json(); // Get error response if available
        setMessage(`فشل في تحميل الصورة: ${errorData.message || 'خطأ غير معروف'}`);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setMessage('فشل في تحميل الصورة');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        maxWidth: 500,
        mx: 'auto',
        border: '1px solid #ddd',
        borderRadius: 2,
        backgroundColor: '#fff',
        boxShadow: 3,
        textAlign: 'center',
      }}
    >
      <Typography variant="h5" gutterBottom>
        تحميل صورة إلى المعرض
      </Typography>
      <Box sx={{ mb: 3 }}>
        <TextField
          type="file"
          inputProps={{ accept: 'image/*' }}
          onChange={handleImageChange}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        onClick={handleUpload}
        disabled={uploading}
        sx={{ minWidth: 150 }}
      >
        {uploading ? <CircularProgress size={24} color="inherit" /> : 'تحميل الصورة'}
      </Button>
      {message && (
        <Typography
          variant="body2"
          color={message.startsWith('فشل') ? 'error' : 'success'}
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default GalleryUpload;
