import React from 'react';
import './About.css'; // Optional: Add some CSS to style the page

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>عن مدرستنا</h1>

      <section className="mission-vision">
        <h2>رسالتنا</h2>
        <p>
          رسالتنا هي تمكين الطلاب من أن يصبحوا متعلمين مدى الحياة ومواطنين مسؤولين مستعدين للنجاح في عالم دائم التغير. نحن نركز على التفكير النقدي، والإبداع، والتعاون في بيئة داعمة وشاملة.
        </p>

        <h2>رؤيتنا</h2>
        <p>
          نحن نتطلع إلى مستقبل يتمكن فيه جميع طلابنا من تحقيق أعلى إمكاناتهم وإحداث تأثير إيجابي في المجتمع. نحن نسعى لأن نكون مؤسسة تعليمية رائدة تُعرف بالتميز الأكاديمي والالتزام بالتنمية الشاملة.
        </p>
      </section>

      <section className="values">
        <h2>قيمنا</h2>
        <ul>
          <li><strong>التميز:</strong> السعي لتحقيق أعلى المعايير في كل ما نقوم به.</li>
          <li><strong>النزاهة:</strong> الالتزام بالصدق والمبادئ الأخلاقية القوية.</li>
          <li><strong>الاحترام:</strong> تعزيز ثقافة اللطف والتعاطف.</li>
          <li><strong>الابتكار:</strong> تشجيع الإبداع والتفكير المستقبلي في جميع جوانب التعلم.</li>
        </ul>
      </section>

      <section className="staff">
        <h2>فريقنا</h2>
        <div className="staff-cards">
          <StaffCard
            name="د. جين دو"
            title="المديرة"
            experience="دكتوراه في القيادة التربوية، 20 عامًا من الخبرة"
            description="تعمل الدكتورة جين دو بشغف وتفانٍ لضمان أن تكون مدرستنا مكانًا ينجح فيه كل طالب. تركز على التميز الأكاديمي وخلق بيئة داعمة لجميع الطلاب والموظفين."
          />
          <StaffCard
            name="السيد جون سميث"
            title="نائب المدير"
            experience="ماجستير في تطوير المناهج، 15 عامًا من الخبرة"
            description="السيد جون سميث ملتزم بتحسين تجربة التعلم في [اسم مدرستك]. يشرف على تخطيط وتنفيذ المناهج الدراسية، مما يضمن أن تكون برامجنا ممتعة وفعالة."
          />
          <StaffCard
            name="السيدة إميلي ديفيس"
            title="المستشارة المدرسية"
            experience="ماجستير في الإرشاد المدرسي، 10 سنوات من الخبرة"
            description="السيدة إميلي ديفيس تدعم الرفاهية العاطفية والاجتماعية لطلابنا. هي دائمًا متاحة لمساعدة الطلاب على تجاوز التحديات وتحقيق أهدافهم الشخصية."
          />
          <StaffCard
            name="السيدة سارة لي"
            title="رئيسة قسم الرياضيات"
            experience="ماجستير في الرياضيات، 12 عامًا من الخبرة"
            description="السيدة سارة لي تجلب حبها للأرقام إلى الفصل الدراسي، وتلهم الطلاب للتفوق في الرياضيات. تقود قسم الرياضيات مع التركيز على تطوير مهارات حل المشكلات القوية."
          />
          <StaffCard
            name="السيد جيمس براون"
            title="رئيس قسم العلوم"
            experience="ماجستير في علم الأحياء، 14 عامًا من الخبرة"
            description="السيد جيمس براون شغوف بالاكتشاف العلمي. يضمن أن يكون منهج العلوم لدينا قائمًا على الاستقصاء العملي، مما يشجع الطلاب على الاستكشاف والتجربة."
          />
          <StaffCard
            name="السيدة ليندا جونسون"
            title="رئيسة قسم العلوم الإنسانية"
            experience="ماجستير في التاريخ، 13 عامًا من الخبرة"
            description="السيدة ليندا جونسون مكرسة لتنمية فهم عميق للتاريخ والثقافة لدى طلابنا. تقود قسم العلوم الإنسانية مع التركيز على التفكير النقدي والتحليل."
          />
        </div>
      </section>

      <section className="contact-info">
        <h2>تواصل معنا</h2>
        <p>
          <strong>العنوان:</strong> 123 شارع المدرسة، مدينة التعليم، 45678<br />
          <strong>الهاتف:</strong> (123) 456-7890<br />
          <strong>البريد الإلكتروني:</strong> info@yourschoolname.edu
        </p>
      </section>
    </div>
  );
};

const StaffCard = ({ name, title, experience, description }) => {
  return (
    <div className="staff-card">
      <h3>{name}</h3>
      <h4>{title}</h4>
      <p><strong>{experience}</strong></p>
      <p>{description}</p>
    </div>
  );
};

export default AboutPage;
