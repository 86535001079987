import React, {useState, useEffect} from 'react';
import {BASE_URL} from "../utils/BaseUrl";
import Navbar from "./navBar/Navbar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Container} from "@mui/system";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid, List, ListItem, ListItemText,
  MenuItem, Paper,
  Select, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  TextField,
  Typography
} from "@mui/material";
import {InputLabel} from "@material-ui/core";
import {create} from "jss";
import rtl from "jss-rtl";
import {jssPreset} from "@mui/styles";
import axios from "axios";
// JSS setup for RTL
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

// MUI Theme with RTL support
const theme = createTheme({

  typography: {
    fontFamily: `"Cairo", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

class SchoolSubject {
  constructor (name, maxGrade, minGrade) {
    this.name = name;
    this.maxGrade = maxGrade;
    this.minGrade = minGrade;
  }
}

// Define subjects for various classes
const secondaryTenthSubjects = [
  new SchoolSubject('هندسة', 200, 80),
  new SchoolSubject('جبر', 200, 80),
  new SchoolSubject('علم احياء', 200, 80),
  new SchoolSubject('فيزياء', 200, 80),
  new SchoolSubject('كيمياء', 200, 80),
  new SchoolSubject('لغة عربية', 500, 200),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 400, 160),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('تربية وطنية', 200, 80),
  new SchoolSubject('جغرافيا', 200, 80),
  new SchoolSubject('تاريخ', 200, 80),
  new SchoolSubject('فلسفة', 200, 80),
  new SchoolSubject('اثراء', 200, 80),
];
const secondarySubjects = [
  new SchoolSubject('هندسة', 300, 120),
  new SchoolSubject('جبر', 300, 120),
  new SchoolSubject('علم احياء', 300, 120),
  new SchoolSubject('فيزياء', 400, 120),
  new SchoolSubject('كيمياء', 200, 80),
  new SchoolSubject('لغة عربية', 400, 160),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 300, 120),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('تربية وطنية', 200, 80),
];
const elementarySubjects = [
  new SchoolSubject('رياضيات', 600, 240),
  new SchoolSubject('لغة عربية', 600, 300),
  new SchoolSubject('لغة فرنسية \\ روسية', 300, 120),
  new SchoolSubject('لغة انكليزية', 400, 160),
  new SchoolSubject('حاسوب', 200, 80),
  new SchoolSubject('اجتماعيات', 600, 240),
  new SchoolSubject('اثراء', 200, 80),
  new SchoolSubject('تربية إسلامية', 200, 80),
  new SchoolSubject('فيزياء وكيماء', 200, 80),
  new SchoolSubject('علوم', 200, 80),
];

const subjectsByClass = {
  'الأول': elementarySubjects,
  'الثاني': elementarySubjects,
  'الثالث': elementarySubjects,
  'الرابع': elementarySubjects,
  'الخامس': elementarySubjects,
  'السادس': elementarySubjects,
  'السابع': elementarySubjects,
  'الثامن': elementarySubjects,
  'التاسع': elementarySubjects,
  'العاشر': secondaryTenthSubjects,
  'الحادي عشر': secondarySubjects,
  'الثاني عشر': secondarySubjects,
};
const StudentWeeklyExam = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExamInfo, setLoadingExamInfo] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [examResults, setExamResults] = useState([]); // Initialize as an empty array
  const [error, setError] = useState(null);
  const semesters = ['الأول', 'الثاني'];
  const [newGrade, setNewGrade] = useState('');
  const [newDate, setNewDate] = useState('');
  const [studentToAddGrade, setStudentToAddGrade] = useState(null);

  useEffect(() => {
    if (selectedClass) {
      loadStudents();
    }
  }, [selectedClass]);

  // Fetching data from the backend

  // Use the student's ID for the API call
  const fetchWeeklyExamResults = async (name) => {
    setLoading(true);
    setError(null); // Reset error state before fetching

    try {
      const student = students.find(s => s.name === name);
      setStudentToAddGrade(student);
      if (!student) {
        setError('Student not found');
        setLoading(false);
        return;
      }

      // Create the URL with query parameters manually
      const url = new URL(BASE_URL + 'api/student/get/weeklyExam');
      url.searchParams.append('id', student.id);
      url.searchParams.append('studentSubject', selectedSubject);
      url.searchParams.append('semester', selectedSemester);

      // Fetch the data using the URL with query parameters
      const response = await fetch(url);

      // Check if the response is OK (status 200)
      if (!response.ok) {
        setError('Failed to fetch exam results');
      }

      const data = await response.json();

      setExamResults(data.results);
      setLoading(false);
    } catch (error) {
      setError('لا توجد نتائج');
      setLoading(false);
    }
  };

  const loadStudents = async () => {
    setStudents([]);
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}getAllStudents?studentClass=${selectedClass}`);
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      alert("Error fetching students:");
    } finally {
      setLoading(false);
    }
  };
  const loadSubjectsForClass = (selectedClass) => {
    const subjects = subjectsByClass[ selectedClass ] || [];
    setSubjects(subjects);
    setSelectedSubject(''); // Reset selected subject when class changes
  };
  const handleStudentSelect = (studentName) => {
    setSelectedStudent(studentName); // This will trigger the useEffect above
    fetchWeeklyExamResults(studentName);
  };

  async function deleteExamResult (id) {
    // Prompt the user for confirmation in Arabic
    const isConfirmed = window.confirm("هل أنت متأكد أنك تريد حذف نتيجة الامتحان؟");

    if (!isConfirmed) {
      // If the user cancels, simply return and do nothing
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}api/student/delete/weeklyExam?id=${id}`);

      // Assuming the API call is successful, update the exam results state
      setExamResults((prevResults) => prevResults.filter((result) => result.id !== id));
    } catch (error) {
      alert("حدث خطأ أثناء حذف نتيجة الامتحان:");
    } finally {
      setLoading(false);
    }
  }

  async function addExamResult () {

    try {
      const response = await fetch(`${BASE_URL}api/student/save/weeklyExam`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: null,
          studentId: studentToAddGrade.id,
          semester: selectedSemester,
          studentClass: studentToAddGrade.studentClass,
          studentSubject: selectedSubject,
          examResult: newGrade,
          date: newDate,
        }),
      });

      if (response.ok) {
        // Convert the response to JSON to get the saved result data
        const savedResult = await response.json();

        // Success message in Arabic
        alert('تم حفظ النتيجة بنجاح');

        // Add the newly saved result to the existing examResults state
        setExamResults((prevResults) => [
          ...prevResults,
          {
            id: savedResult.id, // Use the ID from the saved result
            examResult: savedResult.examResult,
            date: savedResult.date,
          },
        ]);
      } else {
        // Error message in Arabic (if response is not OK)
        alert('حدث خطأ أثناء حفظ النتيجة. يرجى المحاولة مرة أخرى.');
      }
    } catch (error) {
      // Catch network or other errors
      alert('حدث خطأ في الاتصال بالخادم. يرجى المحاولة لاحقًا.');
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{backgroundColor: '#f4f6f8', minHeight: '100vh', direction: "rtl"}}>
        <Navbar/>
        <Container maxWidth="xl" sx={{py: 2}}>
          {/* Title */}
          <Typography variant="h4" gutterBottom align="center" dir={"rtl"}>
            نتائج امتحانات الطلاب
          </Typography>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <InputLabel>الفصل</InputLabel>
                <Select
                  value={selectedSemester}
                  onChange={(e) => setSelectedSemester(e.target.value)}
                  label="الفصل"
                >
                  {semesters.map((semester, index) => (
                    <MenuItem key={index} value={semester} dir="rtl">
                      {semester}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <InputLabel>الصف</InputLabel>
                <Select
                  value={selectedClass}
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                    loadSubjectsForClass(e.target.value);
                  }}
                  label="الصف"
                >
                  <MenuItem value="">اختر الصف</MenuItem>
                  {Object.keys(subjectsByClass).map((classOption, index) => (
                    <MenuItem key={index} value={classOption} dir="rtl">
                      {classOption}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth margin="normal">
                <InputLabel>المادة</InputLabel>
                <Select
                  value={selectedSubject}
                  onChange={(e) => {
                    setSelectedSubject(e.target.value);
                    setExamResults([])
                  }}
                  label="المادة"
                >
                  <MenuItem value="">اختر المادة</MenuItem>
                  {subjects.map((subject, index) => (
                    <MenuItem key={index} value={subject.name}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={4}>
            {loadingExamInfo && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress/>
                <Typography sx={{ml: 2}}>جاري تحميل درجات الامتحانات...</Typography>
              </Box>
            )}
            {students.length > 0 && selectedSubject && (
              <Box mt={4} align="center" dir={"rtl"}>
                <Grid item xs={14} align="center" dir={"rtl"}>
                  <Grid container align="center" dir={"rtl"}>
                    <Grid item xs={4} align="center" dir={"rtl"}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100vh', // Full viewport height for a side panel feel
                          backgroundColor: '#f4f4f4', // Light background for the sidebar
                          padding: 2, // Add some padding for layout
                          overflowY: 'auto', // Allow scrolling if the list is too long
                        }}
                        align="center" dir={"rtl"}
                      >
                        <List align="center" dir={"rtl"}>
                          {students.map((student, index) => (
                            <ListItem
                              button
                              key={index}
                              onClick={() => handleStudentSelect(student.name)}
                              selected={selectedStudent === student.name}
                              align="center" dir={"rtl"}
                            >
                              <ListItemText primary={student.name}/>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <TableContainer component={Paper} direction="rtl" style={{maxHeight: 600, overflow: 'auto'}}>
                        <Table stickyHeader>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <TextField
                                  label="الدرجة"
                                  value={newGrade}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
                                      setNewGrade(value); // Only set value if it's between 0 and 100 or empty
                                    }
                                  }}
                                  variant="outlined"
                                  fullWidth
                                  type="number"
                                  inputProps={{min: 0, max: 100}} // Setting min and max
                                />
                              </TableCell>

                              <TableCell>
                                <TextField
                                  label="التاريخ"
                                  type="date"
                                  value={newDate}
                                  onChange={(e) => setNewDate(e.target.value)}
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{shrink: true}}
                                />
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={addExamResult}
                                >
                                  إضافة
                                </Button>
                              </TableCell>
                            </TableRow>
                            {loading ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  تحميل...
                                </TableCell>
                              </TableRow>
                            ) : error ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center" style={{color: 'red'}}>
                                  {error}
                                </TableCell>
                              </TableRow>
                            ) : examResults.length === 0 ? (
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  لم يتم العثور على نتائج الامتحان.
                                </TableCell>
                              </TableRow>
                            ) : (

                              examResults.map((result) => (
                                <TableRow key={result.id}>
                                  <TableCell>{result.examResult}</TableCell> {/* Grade */}
                                  <TableCell>{result.date}</TableCell> {/* Date */}
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => deleteExamResult(result.id)}
                                    >
                                      حذف
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
};

// Define subjects for various classes

export default StudentWeeklyExam;
