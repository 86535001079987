import {Box, DialogActions, FormControl} from "@mui/material";
import {Dialog, DialogContent, DialogTitle, InputLabel, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {SaveAsOutlined} from "@mui/icons-material";
import React, {useState} from "react";
import swal from "sweetalert";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
}));

const StudentRegistrationPayment = ({open, close, BASE_URL}) => {
  const classes = useStyles();
  const [name, setName] = useState('')
  const [placeDateBirth, setPlaceDateBirth] = useState('')
  const [bloodType, setBloodType] = useState('')
  const [discount, setDiscount] = useState(0)
  const [downPayment, setDownPayment] = useState(0)
  const [fatherPhone, setFatherPhone] = useState('')
  const [motherPhone, setMotherPhone] = useState('')
  const [studentAddress, setStudentAddress] = useState('')
  const [email, setEmail] = useState('')
  const [yearOfRegistration, setYearOfRegistration] = useState('')
  const [studentClass, setStudentClass] = useState('')

  const fetchQr = async (id) => {
    try {
      const params = new URLSearchParams({id: id});
      const fullUrl = `${BASE_URL}generateStudentQRCodeLogin?${params.toString()}`;
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*/*',
        },
      });

      if (response.ok) {
        const qrBlob = await response.blob();
        return URL.createObjectURL(qrBlob);
      } else {

        return null;
      }
    } catch (error) {
      swal('خطأ في جلب رمز الاستجابة السريعة:', error);
      return null;
    }
  };

  const onSaveButtonClick = async () => {
    const apiUrl = BASE_URL + 'saveStudentInfo';

    try {
      if(name.length===0 || studentClass.length===0 ||yearOfRegistration.length===0) {
        await swal('Please check the name Or Student class Or year Of registration');
      }
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          email: email,
          studentClass: studentClass,
          placeDateBirth: placeDateBirth,
          bloodType: bloodType,
          fatherPhone: fatherPhone,
          motherPhone: motherPhone,
          studentAddress: studentAddress,
          yearOfRegistration: yearOfRegistration,
          discount: discount,
          downPayment: downPayment
        })
      });
      if (response.status === 400) {
        await swal('Please check the name.');
        return
      }

      if (response.status === 500) {
        await swal('Internal Error.');
        return
      }
      const data = await response.json();
      // Fetch the QR code after saving the student info
      const qrUrl = await fetchQr(data.studentId);

      // Create a custom div to show the image
      const contentDiv = document.createElement('div');
      contentDiv.style.textAlign = 'right';  // Align text to the right for Arabic

      const titleElement = document.createElement('h2');
      titleElement.textContent = "تم إنشاء الحساب بنجاح";
      titleElement.style.color = '#1976d2';
      titleElement.style.marginBottom = '20px';
      titleElement.style.textAlign = 'center';  // Center the title
      contentDiv.appendChild(titleElement);

      // Create and format the user details
      const textElement = document.createElement('div');
      textElement.style.marginBottom = '20px';
      textElement.style.fontSize = '16px';
      textElement.style.lineHeight = '1.8';
      textElement.style.direction = 'rtl';
      textElement.innerHTML = `اسم الطالب: ${name}<br>اسم المستخدم: ${data.email}<br>كلمة المرور: ${data.password}`;
      contentDiv.appendChild(textElement);

      const imgElement = document.createElement('img');
      imgElement.src = qrUrl;  // Use the fetched QR code URL
      imgElement.alt = "رمز الاستجابة السريعة";
      imgElement.style.marginTop = '20px';
      imgElement.style.width = '100%';
      imgElement.style.maxWidth = '200px';
      imgElement.style.display = 'block';
      imgElement.style.margin = '0 auto'; // Center the QR code
      contentDiv.appendChild(imgElement);
      // Resetting the form fields
      setName('');
      setEmail('');
      setStudentClass('');
      setPlaceDateBirth('');
      setBloodType('');
      setFatherPhone('');
      setMotherPhone('');
      setStudentAddress('');
      setYearOfRegistration('');
      setDiscount(0);
      setDownPayment(0);
      // Displaying the success alert with the QR code image
      swal({
        title: null,  // Set title to null to remove the default title
        content: contentDiv,
        buttons: {
          confirm: {
            text: 'حسناً',
            value: true,
            visible: true,
            className: 'swal-button--confirm',
            closeModal: true
          }
        },
      });
    } catch (err) {
      await swal("فشل", "خطأ");
    }
  }

  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + year);
  return (
    <>
      <Box sx={{minWidth: 120}} border={'blue'}>

        <Grid>
          <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={close}
            PaperProps={{
              component: 'form',
              onSubmit: {
                onSaveButtonClick
              },
            }}
            align={'center'}>
            <DialogTitle>استمارة تسجيل تلميذ</DialogTitle>
            <DialogContent dir="rtl" className={classes.dialogContent}>
              <Box component="form">
                <TextField
                  id={"studentFullName"}
                  autoFocus
                  required
                  margin="dense"
                  dir="rtl"
                  label="اسم الطالب الثلاثي"
                  type={"text"}
                  fullWidth
                  variant="standard"
                  onChange={(ev) => setName(ev.target.value)}
                  InputLabelProps={{position: 'end'}}
                />
                <TextField
                  id={"studentEmail"}
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="البريد الإلكتروني"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(ev) => setEmail(ev.target.value)}
                />
                <FormControl className={classes.formControl} variant="outlined" required>
                  <InputLabel id="demo-controlled-open-select-label">الصف</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    defaultValue={studentClass}
                    label="الصف"
                    fullWidth
                    onChange={e => setStudentClass(e.target.value)}
                  >
                    <MenuItem value="الأول" dir="rtl">الأول</MenuItem>
                    <MenuItem value="الثاني" dir="rtl">الثاني</MenuItem>
                    <MenuItem value="الثالث" dir="rtl">الثالث</MenuItem>
                    <MenuItem value="الرابع" dir="rtl">الرابع</MenuItem>
                    <MenuItem value="الخامس" dir="rtl">الخامس</MenuItem>
                    <MenuItem value="السادس" dir="rtl">السادس</MenuItem>
                    <MenuItem value="السابع" dir="rtl">السابع</MenuItem>
                    <MenuItem value="الثامن" dir="rtl">الثامن</MenuItem>
                    <MenuItem value="التاسع" dir="rtl">التاسع</MenuItem>
                    <MenuItem value="العاشر" dir="rtl">العاشر</MenuItem>
                    <MenuItem value="الحادي عشر" dir="rtl">الحادي عشر</MenuItem>
                    <MenuItem value="الثاني عشر" dir="rtl">الثاني عشر</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id={"studentBirthAndPlace"}
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  type="text"
                  label="محل وتاريخ الولادة"
                  onChange={(ev) => setPlaceDateBirth(ev.target.value)}
                  fullWidth
                  variant="standard"
                />
                <FormControl className={classes.formControl} variant="outlined" required>
                  <InputLabel htmlFor='selected-language'>فصيلة الدم</InputLabel>
                  <Select
                    dir={'rtl'}
                    labelId="demo-simple-select-label"
                    fullWidth
                    defaultValue={bloodType}
                    onChange={e => setBloodType(e.target.value)}
                  >
                    <MenuItem dir="rtl" value="A+">A+</MenuItem>
                    <MenuItem dir="rtl" value="A-">A-</MenuItem>
                    <MenuItem dir="rtl" value="B+">B+</MenuItem>
                    <MenuItem dir="rtl" value="B-">B-</MenuItem>
                    <MenuItem dir="rtl" value="AB+">AB+</MenuItem>
                    <MenuItem dir="rtl" value="AB-">AB-</MenuItem>
                    <MenuItem dir="rtl" value="O+">O+</MenuItem>
                    <MenuItem dir="rtl" value="O-">O-</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id={"studentFullName"}
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="رقم هاتف الأب"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(ev) => setFatherPhone(ev.target.value)}
                />
                <TextField
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="رقم هاتف الأم"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(ev) => setMotherPhone(ev.target.value)}
                />
                <TextField
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="عنوان المنزل"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(ev) => setStudentAddress(ev.target.value)}
                />
                <FormControl className={classes.formControl} variant="outlined" required>
                  <InputLabel htmlFor='selected-language'>سنة</InputLabel>
                  <Select
                    dir={'rtl'}
                    fullWidth
                    onChange={e => setYearOfRegistration(e.target.value)}>
                    {
                      years.map((year, index) => {

                        return <MenuItem dir={'rtl'} key={`year${index}`}
                                         value={year}>{year}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <TextField
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="خصم%"
                  type="number"
                  fullWidth
                  variant="standard"
                  value={discount}
                  defaultValue={0}
                  onChange={(ev) => setDiscount(ev.target.value)}
                />
                <TextField
                  dir="rtl"
                  autoFocus
                  required
                  margin="dense"
                  label="دفعة أولى"
                  type="number"
                  fullWidth
                  variant="standard"
                  value={downPayment}
                  defaultValue={0}
                  onChange={(ev) => setDownPayment(ev.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button size="large"
                      color="secondary" variant="contained"
                      onClick={close}>إغلاق</Button>
              <Button size="large"
                      color="primary"
                      endIcon={<SaveAsOutlined/>}
                      variant="contained"
                      onClick={onSaveButtonClick}>حفظ</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </>
  );
}

export default StudentRegistrationPayment;