import * as React from 'react';
 import HomePage from "../index/HomePage";
import Navbar from "./navBar/Navbar";
import { ThemeProvider, createTheme } from '@mui/material/styles';


export default function TabViewExample() {
    const token = localStorage.getItem('accessToken');
    const admin = localStorage.getItem('admin');
    if (!token || !admin) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('id');
        localStorage.removeItem('admin');
        return <HomePage/>
    }
    const theme = createTheme();
    return (
      <ThemeProvider theme={theme}>
        <Navbar style={{ backgroundColor: 'transparent' }}/>
      </ThemeProvider>

    )
}