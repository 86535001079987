import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {SaveAsOutlined} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import Grid from "@material-ui/core/Grid";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Box, DialogActions, Typography} from "@mui/material";

const StudentPayment = ({selectedRow, handleClickOpenPaymentDialog, handleClosePaymentDialog, BASE_URL}) => {

  const [payment, setPayment] = useState(0);
  const [paymentsDone, setPaymentsDone] = useState([]);
  const [studentTotalPayments, setStudentTotalPayments] = useState([]);
  useEffect(() => {
    if (selectedRow && handleClickOpenPaymentDialog) {
      getPayments();
    }
  }, [selectedRow, handleClickOpenPaymentDialog]);
  if (selectedRow === null) return;
  if (handleClickOpenPaymentDialog !== true) {
    return null
  }

  const addPayment = async () => {
    const apiUrl = BASE_URL + 'addStudentPayment';

    if (payment === 0 && selectedRow.length === 0) {
      await swal("Failed", "error")
      return;
    }
    // Convert query parameters to a string
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({
          id: selectedRow.id,
          payment: payment
        })
      })
      if (response.ok) {
        await swal("Success")
        setPayment(0)

      } else if (response.status === 413) {
        await swal("Failed", "Amount too big");
      } else if (response.status === 500) {
        await swal("Failed", "Nothing to Pay");
      }
    } catch (err) {
      await swal(err);
    }
  }

  async function getPayments () {
    const apiUrl = BASE_URL + 'getStudentAllPayment';
    try {
      let params = new URLSearchParams();
      params.append('id', selectedRow.id);
      const paramString = params.toString()
      const fullUrl = `${apiUrl}?${paramString}`;
      const response = await fetch(fullUrl);
      if (!response) {
        return;
      }
      if (response.status === 200) {
        const data = await response.json()

        setStudentTotalPayments(data.totalPayments)
        setPaymentsDone(data.studentsAllPaymentsDbList)

      } else if (response.status === 404) {
        await swal("Failed", "Not found");
      }
    } catch (err) {
      await swal(err);
    }
  }

  const handelClose = () => {
    setPaymentsDone([]);
    handleClosePaymentDialog();

  }

  {
    return (

      <Box>
        <Grid>
          <Dialog
            open={handleClickOpenPaymentDialog}
            onClose={handelClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
              component: 'form',
              // style: { maxWidth: '1200px' } // Adjust width as needed
            }}
          >
            <DialogTitle style={{backgroundColor: '#f44336', color: '#fff'}} align="center">المدفوعات
              المالية</DialogTitle>

            <DialogContent>
              <Typography variant="h6" align="center" gutterBottom>
                التكلفة السنوية: {selectedRow.yearly_cost}
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                إجمالي المدفوعات: {studentTotalPayments}
              </Typography>
              <Typography variant="h6" align="center" gutterBottom>
                المبلغ المتبقي: {selectedRow.yearly_cost - studentTotalPayments}
              </Typography>

              <div className={'notes'}>
                {paymentsDone.map((e) => (
                  <div className={'notes-item'} key={e.paymentNumber}>
                    <div style={{width: "100%"}}>
                      <Typography variant="body1" align="right" gutterBottom>
                        تاريخ الدفع: {e.paymentDate}
                      </Typography>
                      <Typography variant="body1" align="right" gutterBottom>
                        رقم الدفع: {e.paymentNumber}
                      </Typography>
                      <Typography variant="body1" align="right" gutterBottom>
                        مبلغ الدفع: {e.paymentAmount}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>

              <TextField
                fullWidth
                dir="rtl"
                autoFocus
                required
                margin="dense"
                label="دفعة"
                type="number"
                variant="outlined"
                onChange={(ev) => setPayment(ev.target.value)}
                style={{marginBottom: '16px'}}
              />
            </DialogContent>
            <DialogActions>
              <Button color="secondary" variant="contained" onClick={handelClose}>إغلاق</Button>
              <Button size="large" color="primary" endIcon={<SaveAsOutlined/>} variant="contained"
                      onClick={addPayment}>حفظ</Button>
              <Button size="large" color="primary" variant="contained" onClick={() => getPayments()}>عرض</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>

    );
  }
}
export default StudentPayment;