import React, {useEffect, useRef} from 'react';
import Message from "./Message";

const MessageList = ({ messages }) => {
    const endOfMessagesRef = useRef(null);

    useEffect(() => {
        // Scroll to the end of the message list whenever messages change
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);

    return (
        <div className="message-list">
            {sortedMessages.map((message, index) => (
                <Message key={index} {...message} />
            ))}
            <div ref={endOfMessagesRef}/>
        </div>
    );
};

export default MessageList;