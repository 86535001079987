import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
 import NoteIcon from '@mui/icons-material/Note';
import {Container, styled} from '@mui/system';
import Calendar from "react-calendar";

const StyledCalendar = styled(Calendar)({
    '& .MuiCalendarPicker-daysHeader': {
        backgroundColor: '#f0f0f0',
    },
    '& .MuiButtonBase-root': {
        borderRadius: '0',
    },
});

const AbsentDays = ({ isOpen, onClose, selectedRow,BASE_URL }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const [note, setNote] = useState('');
    const [absentDays, setAbsentDays] = useState([]);
    const [savedNotes, setSavedNotes] = useState({});

    useEffect(() => {
        if (isOpen && selectedRow) {
            // Fetch absent days and notes for the selected student from the backend based on selectedRow.id
            // Example: fetchAbsentDays(selectedRow.id);
            const fetchedAbsentDays = []; // Example: Fetch absent days from backend
            const fetchedNotes = {}; // Example: Fetch notes from backend
            setAbsentDays(fetchedAbsentDays);
            setSavedNotes(fetchedNotes);
        }
    }, [isOpen, selectedRow]);

    const handleDateChange = (dates) => {
        // Update selected dates
        setSelectedDates(dates);
    };

    const handleSaveAbsentDays = () => {
        // Handle saving absent days and note to the database or perform other actions

        // Example: saveAbsentDays(selectedDates, note);
        const updatedNotes = {};
        selectedDates.forEach((date) => {
            updatedNotes[date.toDateString()] = note;
        });
        setSavedNotes({ ...savedNotes, ...updatedNotes });
    };

    const renderNoteIcon = (date) => {
        const hasNote = selectedDates.find((d) => d.toDateString() === date.toDateString()) || savedNotes[date.toDateString()];
        return hasNote ? <NoteIcon /> : null;
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle align="center">إدارة أيام الغياب</DialogTitle>
            <DialogContent>
                <StyledCalendar
                    renderDay={(day, _value, DayComponentProps) => {
                        const hasNote = selectedDates.find((d) => d.toDateString() === day.toDateString()) || savedNotes[day.toDateString()];
                        return (
                            < Container style={{ position: 'relative' }}>
                                {DayComponentProps.children}
                                {hasNote && <NoteIcon style={{ position: 'absolute', top: '4px', right: '4px', color: 'red' }} />}
                            </Container>
                        );
                    }}
                    value={selectedDates}
                    onChange={handleDateChange}
                    renderDayIcon={renderNoteIcon}
                    views={['month']}
                    disablePast
                    showDaysOutsideCurrentMonth
                    variant="static"
                />
                <TextField
                    label="ملاحظة"
                    variant="outlined"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    style={{ marginTop: '20px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveAbsentDays} color="primary" variant="contained">
                    حفظ أيام الغياب
                </Button>
                <Button onClick={onClose} color="secondary" variant="contained">
                    إغلاق
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AbsentDays;
