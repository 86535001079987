import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const FinalResultsDialog = ({ isOpen, onClose ,selectedRow,BASE_URL}) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Final Results</DialogTitle>
            <DialogContent>
                {/* Add your final results content here */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                  إغلاق
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FinalResultsDialog;
