import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@mui/material';
import StudentPayment from "./Money/StudentPayment";
import Chat from "./Chat/Chat";
import RewardsDialog from "./RewardsDialog";
import PunishmentsDialog from "./PunishmentsDialog";
import YearResultDialog from "./YearResultDialog";
import FinalResultsDialog from "./FinalResultsDialog";
import AbsentDays from "./AbsentDays";
import PasswordQRCodeDialog from "./PasswordQRCodeDialog";

const ControlDialog = ({isOpen, onClose, admin, selectedRow, BASE_URL}) => {
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [openMoneyDialog, setOpenMoneyDialog] = useState(false);
  const [openRewardsDialog, setOpenRewardsDialog] = useState(false);
  const [openPunishmentsDialog, setOpenPunishmentsDialog] = useState(false);
  const [openYearResultDialog, setOpenYearResultDialog] = useState(false);
  const [openFinalResultsDialog, setOpenFinalResultsDialog] = useState(false);
  const [openAbsentDaysDialog, setOpenAbsentDaysDialog] = useState(false);
  const [openPasswordQRCodeDialog, setOpenPasswordQRCodeDialog] = useState(false);

  const handleOpenChat = () => setOpenChatDialog(true);
  const handleOpenMoney = () => setOpenMoneyDialog(true);
  const handleOpenRewards = () => setOpenRewardsDialog(true);
  const handleOpenPunishments = () => setOpenPunishmentsDialog(true);
  const handleOpenYearResult = () => setOpenYearResultDialog(true);
  const handleOpenFinalResults = () => setOpenFinalResultsDialog(true);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle align={'center'}>معلومات الطالب</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleOpenChat}>
              ملاحظات الأهل - رسالة الإدارة
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="secondary" fullWidth size="large" onClick={handleOpenMoney}>
              المدفوعات المالية
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="success" fullWidth size="large" onClick={handleOpenRewards}>
              المكافآت
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="error" fullWidth size="large" onClick={handleOpenPunishments}>
              العقوبات
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="warning" fullWidth size="large" onClick={handleOpenYearResult}>
              نتيجة أعمال السنة
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" color="info" fullWidth size="large" onClick={handleOpenFinalResults}>
              المحصلات النهائية
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="error" fullWidth size="large"
                    onClick={() => setOpenAbsentDaysDialog(true)}>
              الغياب
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="error" fullWidth size="large"
                    onClick={() => setOpenPasswordQRCodeDialog(true)}>
              معلومات الدخول
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          إغلاق
        </Button>
      </DialogActions>
      <Chat admin={admin} selectedRow={selectedRow} open={openChatDialog} close={() => setOpenChatDialog(false)}
            BASE_URL={BASE_URL}/>
      <StudentPayment selectedRow={selectedRow} handleClickOpenPaymentDialog={openMoneyDialog}
                      handleClosePaymentDialog={() => setOpenMoneyDialog(false)} BASE_URL={BASE_URL}/>
      <RewardsDialog isOpen={openRewardsDialog} onClose={() => setOpenRewardsDialog(false)} selectedRow={selectedRow}
                     BASE_URL={BASE_URL}/>
      <PunishmentsDialog isOpen={openPunishmentsDialog} onClose={() => setOpenPunishmentsDialog(false)}
                         selectedRow={selectedRow} BASE_URL={BASE_URL}/>
      <YearResultDialog isOpen={openYearResultDialog} onClose={() => setOpenYearResultDialog(false)}
                        selectedRow={selectedRow} BASE_URL={BASE_URL}/>
      <FinalResultsDialog isOpen={openFinalResultsDialog} onClose={() => setOpenFinalResultsDialog(false)}
                          selectedRow={selectedRow} BASE_URL={BASE_URL}/>
      <AbsentDays isOpen={openAbsentDaysDialog} onClose={() => setOpenAbsentDaysDialog(false)} selectedRow={selectedRow}
                  BASE_URL={BASE_URL}/>
      <PasswordQRCodeDialog isOpen={openPasswordQRCodeDialog} onClose={() => setOpenPasswordQRCodeDialog(false)}
                            selectedRow={selectedRow} BASE_URL={BASE_URL}/>
    </Dialog>
  );
};

export default ControlDialog;