import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../utils/BaseUrl';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const CSVUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null); // Reset error on file change
    setSuccess(null); // Reset success on file change
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("يرجى اختيار ملف CSV أولاً.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    setError(null); // Reset error state before upload
    setSuccess(null); // Reset success state before upload

    try {
      const response = await axios.post(`${BASE_URL}api/students/upload-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSuccess(response.data); // Show success message
    } catch (error) {
      setError("فشل تحميل ملف CSV. يرجى التحقق من التنسيق والمحاولة مرة أخرى."); // Display error message
    } finally {
      setLoading(false); // Stop loading regardless of success or error
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        رفع ملف CSV
      </Typography>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ marginBottom: 16 }}
      />
      <Button
        type="submit"
        size="large"
        color="primary"
        variant="contained"
        endIcon={<UploadFileIcon/>}
        onClick={handleFileUpload}
        disabled={loading}
        sx={{width: {xs: '100%', sm: '80%', md: 'auto'}}}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'رفع CSV'}
      </Button>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="success.main">{success}</Typography>}
    </Box>
  );
};

export default CSVUpload;
