import React, {useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import swal from "sweetalert";
import {Delete as DeleteIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

const AdminTable = ({URL}) => {
  const [admins, setAdmins] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [supervisorAdmin, setSupervisorAdmin] = useState(false);
  const [classes, setClasses] = useState({
    "الأول": false,
    "الثاني": false,
    "الثالث": false,
    "الرابع": false,
    "الخامس": false,
    "السادس": false,
    "السابع": false,
    "الثامن": false,
    "التاسع": false,
    "العاشر": false,
    "الحادي عشر": false,
    "الثاني عشر": false,
  });

  // Define an array for grades 1 through 12
  const grades = [
    "الأول",
    "الثاني",
    "الثالث",
    "الرابع",
    "الخامس",
    "السادس",
    "السابع",
    "الثامن",
    "التاسع",
    "العاشر",
    "الحادي عشر",
    "الثاني عشر"
  ];

  useEffect(() => {
    // Fetch the admins from the backend
    const fetchAdmins = async () => {
      try {
        const response = await axios.get(URL + "getAllAdmins");
        setAdmins(response.data);
      } catch (error) {
        await swal('خطأ في جلب المسؤولين:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleEditClick = (admin) => {
    setSelectedAdmin(admin);
    setFirstName(admin.firstName);
    setLastName(admin.lastName);
    setEmail(admin.email);
    setSupervisorAdmin(admin.supervisorAdmin);
    setClasses(admin.classes || {});
    setEditDialogOpen(true);
  };
  const navigate = useNavigate();
  const handleClassChange = (grade, checked) => {
    setClasses(prevClasses => ({
      ...prevClasses,
      [ grade ]: checked
    }));
  };
  const handleSave = async () => {
    const updatedAdmin = {
      ...selectedAdmin,
      id: selectedAdmin.id,
      firstName,
      lastName,
      email,
      supervisorAdmin,
      classes,
    };

    try {

      const apiUrl = URL + 'updateAdminInfo';

      await axios.post(apiUrl, updatedAdmin, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin.id === selectedAdmin.id ? updatedAdmin : admin
        )
      );
      setEditDialogOpen(false);
    } catch (error) {
      await swal('خطأ في تحديث المسؤول:', error);
    }
  };

  function openDeleteConfirmModal (id) {
    if (window.confirm('هل أنت متأكد أنك تريد حذف هذا المستخدم؟')) {
      deleteAdmin(id).then((value) => {
        navigate("/adminDashboard");
      });
    }
  }

  async function deleteAdmin (id) {
    const apiUrl = URL + 'deleteAdmin';
    try {
      let params = new URLSearchParams();
      params.append('id', id);
      const paramString = params.toString()
      const fullUrl = `${apiUrl}?${paramString}`;
      const response = await fetch(fullUrl);

      if (response.status !== 200) {
        await swal("فشل", "غير موجود");
      }
    } catch (err) {
      await swal(err);
    }
  }

  return (
    <>
      <TableContainer component={Paper} elevation={3} style={{ marginTop: '20px', padding: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>الاسم الأول</TableCell>
              <TableCell>اسم العائلة</TableCell>
              <TableCell>بريد إلكتروني</TableCell>
              <TableCell>مشرف إداري</TableCell>
              <TableCell>تعديل</TableCell>
              <TableCell>حذف</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.firstName}</TableCell>
                <TableCell>{admin.lastName}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                  <Checkbox checked={admin.supervisorAdmin} disabled />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleEditClick(admin)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => openDeleteConfirmModal(admin.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Dialog */}
      {selectedAdmin && (
        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>تعديل المسؤول</DialogTitle>
          <DialogContent>
            <TextField
              label="الاسم الأول"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="اسم العائلة"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="بريد إلكتروني"
              value={email}
              aria-readonly
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={supervisorAdmin}
                  onChange={(e) => setSupervisorAdmin(e.target.checked)}
                />
              }
              label="مشرف إداري"
              style={{ marginTop: '10px' }}
            />
            <div style={{ marginTop: '20px' }}>
              <Typography variant="h6" gutterBottom>
                الصفوف الدراسية
              </Typography>
              {grades.map((grade) => (
                <FormControlLabel
                  key={grade}
                  control={
                    <Checkbox
                      checked={classes[grade] || false}
                      onChange={(e) => handleClassChange(grade, e.target.checked)}
                    />
                  }
                  label={grade}
                />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} color="primary" variant="contained">
              حفظ
            </Button>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary" variant="outlined">
              إلغاء
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AdminTable;
