import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import AndroidIcon from "@mui/icons-material/Android";

const Footer = ({BASE_URL}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center horizontally
        justifyContent: 'center', // Center vertically if needed
        textAlign: 'center', // Center text inside the Box
        p: 2, // Add padding for spacing
      }}
    >


      {/* Stack for better alignment of text and button */}
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: {
              xs: '1rem',  // Small devices
              sm: '1.2rem',  // Medium devices
              md: '1.5rem',  // Large devices
              lg: '1.7rem',  // Extra large devices
            },
            fontWeight: 'bold',
            mb: 2, // Margin bottom for spacing
          }}
        >
          قم بتنزيل أحدث إصدار من تطبيقنا
        </Typography>

        <Button
          variant="contained"
          href={`${BASE_URL}api/download/apk`}
          download
          sx={{
            mt: 4, // Increased margin-top for more space
            py: 1.5,
            px: 3, // Add horizontal padding for a better shape
            fontSize: '1rem', // Adjust font size for readability
            textTransform: 'none',
            borderRadius: '12px', // Slightly rounded corners for a modern look
            backgroundColor: 'green', // Green background
            color: 'white', // White text for contrast
            boxShadow: 2, // Add a shadow for depth
            '&:hover': {
              backgroundColor: 'darkgreen', // Darker green on hover
              boxShadow: 4, // Elevate shadow on hover for emphasis
            },
            transition: 'background-color 0.3s, box-shadow 0.3s', // Smooth transition for hover effects
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          startIcon={<AndroidIcon sx={{ml: 1}}/>} // Add margin-left to the icon for spacing
        >
          تحميل
        </Button>
      </Stack>


      {/* Footer Note */}
      <Typography
        variant="body2"
        color="white"
        sx={{
          mt: 4,
          fontSize: {xs: '0.75rem', sm: '0.85rem', md: '0.9rem'},
          textAlign: 'center', // Center text for the footer
        }}
      >
        &copy; {new Date().getFullYear()} جميع الحقوق محفوظة مدرسة المعالي الخاصة
      </Typography>
    </Box>

  );
};

export default Footer;
