import React, {useEffect, useRef, useState} from 'react';
import {ThemeProvider as RTLThemeProvider} from '@mui/material/styles';
import {Box, Button, createTheme, Dialog, DialogActions, DialogContent, ThemeProvider} from '@mui/material';
import swal from 'sweetalert';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {Input} from "antd";
import IDCard from "./StudentIDCard";
import axios from "axios";

const PasswordQRCodeDialog = ({isOpen, onClose, selectedRow, BASE_URL}) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [studentImage, setStudentImage] = useState('');
  const [file, setFile] = useState('');
  const [message, setMessage] = useState('');

  const theme = createTheme({
    direction: 'rtl', // Set the text direction to RTL for Arabic
    typography: {
      fontFamily: 'Arial, sans-serif', // You can adjust the font to better suit your design
    },
  });

  useEffect(() => {
    if (selectedRow && isOpen) {
      fetchQr();
      fetchStudentData(selectedRow.id);
    }
    // Cleanup function to revoke the object URL to prevent memory leaks
    return () => {
      if (studentImage) {
        URL.revokeObjectURL(studentImage);
      }
    };
  }, [selectedRow, isOpen, BASE_URL]);

// Inside your component
  const studentCardRef = useRef();
  // Handle image upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file)
      setStudentImage(URL.createObjectURL(file)); // Show preview
    }
  };

  // Upload image to backend
  const uploadImageToBackend = async ( studentId) => {
    if (!file) {
      setMessage('Please select an image to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('studentId', studentId);

    try {
      const response = await axios.post(BASE_URL+'api/upload-student-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data);
    } catch (error) {
      setMessage(`Error uploading image: ${error.response?.data || error.message}`);
    }
  };
  const fetchStudentData = async (studentID) => {
    try {
      const response = await fetch(`${BASE_URL}api/student/${studentID}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json', // Expecting a URL in JSON format
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageUrl = await response.text(); // Since the backend returns the URL directly

      if (imageUrl) {
        setStudentImage(imageUrl); // Use the URL directly
      } else {
        console.warn('No image data found for student:', studentID);
      }
    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  };



  const removeLinkedDevice = async () => {
    try {
      const params = new URLSearchParams({id: selectedRow.id});
      const fullUrl = `${BASE_URL}student/reset/logindevice?${params.toString()}`;
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*/*',
        },
      });

      if (response.ok) {
        alert('تم إزالة الجهاز المرتبط');
        fetchQr();
      } else {
       await swal('Failed to fetch QR code');
      }
    } catch (error) {
     await swal('Error fetching QR code:', error);
    }
  };
  const fetchQr = async () => {
    try {
      const params = new URLSearchParams({id: selectedRow.id});
      const fullUrl = `${BASE_URL}generateStudentQRCodeLogin?${params.toString()}`;
      const response = await fetch(fullUrl, {
        method: 'GET',
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*/*',
        },
      });

      if (response.ok) {
        const qrBlob = await response.blob();
        const qrUrl = URL.createObjectURL(qrBlob);
        setQrCodeUrl(qrUrl);
      } else {
        await swal('فشل جلب رمز الاستجابة السريعة');
      }
    } catch (error) {
      await swal('خطأ في جلب رمز الاستجابة السريعة:', error);
    }
  };


  const saveToPDF = async () => {
    const doc = new jsPDF();
    const pdfWidth = doc.internal.pageSize.getWidth(); // Get PDF page width
    const pdfHeight = doc.internal.pageSize.getHeight(); // Get PDF page height

    // Capture the front side (StudentIDCard)
    const frontCanvas = await html2canvas(studentCardRef.current); // Front side reference
    const frontImgData = frontCanvas.toDataURL('image/png');

    // Get the image's original width and height
    const imgWidth = frontCanvas.width;
    const imgHeight = frontCanvas.height;

    // Calculate the aspect ratio for scaling the image
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    // Calculate new width and height to fit the PDF page
    const newWidth = imgWidth * ratio;
    const newHeight = imgHeight * ratio;

    // Add the front image to the PDF (front side of the card)
    doc.addImage(frontImgData, 'PNG', (pdfWidth - newWidth) / 2, 20, newWidth, newHeight); // Center the image

    // Add a new page to the PDF for the back side
    doc.addPage();

    // Save the PDF with the student's name as the file name
    doc.save(`${selectedRow.name}.pdf`);
  };

  return (
    <ThemeProvider theme={theme}>
      <RTLThemeProvider theme={theme}>
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
          <DialogContent>
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
              {/* Container for the student card */}
              <Box
                mt={2}
                alignItems="center"
                textAlign="center"
                ref={studentCardRef}
                width="100%"  // Make the Box take the full width
                maxWidth="700px" // Ensure max width matches IDCard width
                padding={2}  // Add padding for spacing
              >
                {selectedRow && (
                  <IDCard
                    qrCode={qrCodeUrl}
                    Name={selectedRow.name}
                    Class={selectedRow.studentClass}
                    Phone={selectedRow.fatherPhoneNumber || selectedRow.motherPhoneNumber}
                    Address={selectedRow.studentAddress}
                    StudentNum={selectedRow.studentNum}
                    StudentImage={studentImage}
                  />
                )}
              </Box>

              {/* Image upload input */}
              <Input
                accept="image/*"
                id="student-image-upload"
                type="file"
                onChange={handleImageUpload} // Ensure this calls a function that sets the file
                style={{ display: 'none' }}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            {message && <p>{message}</p>} {/* Display message */}
            <Button
              onClick={() => uploadImageToBackend(selectedRow.id)}
              color="primary"
              variant="contained"
            >
              حفظ الصورة
            </Button>

            {/* Upload button for student image */}
            <label htmlFor="student-image-upload">
              <Button variant="contained" component="span" color="primary">
                تحميل صورة الطالب
              </Button>
            </label>
          </DialogActions>

          {/* Save as PDF button */}
          <DialogActions>
            <Button onClick={saveToPDF} color="primary" variant="contained">
              حفظ كـ PDF
            </Button>
          </DialogActions>

          {/* Remove linked device button */}
          <DialogActions>
            <Button onClick={removeLinkedDevice} color="secondary" variant="contained">
              إزالة الجهاز المرتبط
            </Button>
          </DialogActions>

          {/* Close button */}
          <DialogActions>
            <Button onClick={onClose} color="secondary" variant="contained">
              إغلاق
            </Button>
          </DialogActions>
        </Dialog>
      </RTLThemeProvider>
    </ThemeProvider>

  );
};

export default PasswordQRCodeDialog;