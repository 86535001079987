import React, {useState} from "react";

import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import {InputLabel} from "@material-ui/core";
import swal from "sweetalert";
import Navbar from "./navBar/Navbar";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Container} from "@mui/system";
import { BASE_URL }  from "../utils/BaseUrl"
const YearlyPaymentsTable = () => {

  const [studentYear, setStudentYear] = useState('')
  const [first, setFirst] = useState(0)
  const [second, setSecond] = useState(0)
  const [third, setThird] = useState(0)
  const [forth, setForth] = useState(0)
  const [fifth, setFifth] = useState(0)
  const [sixth, setSixth] = useState(0)
  const [seventh, setSeventh] = useState(0)
  const [eighth, setEighth] = useState(0)
  const [ninth, setNinth] = useState(0)
  const [tenth, setTenth] = useState(0)
  const [eleventh, setEleventh] = useState(0)
  const [twelfth, setTwelfth] = useState(0)

  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(20), (val, index) => index + year);

  async function onGetYearlyCostButtonClick () {
    const apiUrl = BASE_URL + 'getYearlyCost';
    if (studentYear.length === 0) {
      await swal("Error", "Please pick a class to get data.");
      return;
    }
    // Convert query parameters to a string
    try {
      let params = new URLSearchParams();
      params.append('year', studentYear);
      const paramString = params.toString()
      const fullUrl = `${apiUrl}?${paramString}`;
       const response = await fetch(fullUrl);
      if (!response) {
        return;
      }
      if (response.status === 200) {
        const data = await response.json()
         setFirst(data.first);
        setSecond(data.second);
        setThird(data.third);
        setForth(data.forth);
        setFifth(data.fifth);
        setSixth(data.sixth);
        setSeventh(data.seventh);
        setEighth(data.eighth);
        setNinth(data.ninth);
      } else if (response.status === 404) {
        await swal("Failed", "Not found");
      }
    } catch (err) {
     await swal(err);
    }
  }

  async function onSaveYearlyCostButtonClick () {
    const apiUrl = BASE_URL + 'saveYearlyCost';

    // Convert query parameters to a string
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          year: studentYear,
          first: first,
          second: second,
          third: third,
          forth: forth,
          fifth: fifth,
          sixth: sixth,
          seventh: seventh,
          eighth: eighth,
          ninth: ninth,
          tenth: tenth,
          eleventh: eleventh,
          twelfth: twelfth,

        })
      })

      if (response.ok) {
        swal("Success");
      } else {
        await swal("Failed", "error");
      }
    } catch (err) {
      await swal(err);
    }
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#ff5722',
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div style={{backgroundColor: '#f4f6f8', minHeight: '100vh'}}>
        <Navbar/>
        <Container maxWidth="lg" style={{marginTop: '40px'}}>
        {/* Form Control for Year Selection */}
        {/* FormControl for selecting year */}
        <FormControl style={{minWidth: 200, marginBottom: '20px'}} variant="outlined">
          <InputLabel id="selected-language-label">سنة</InputLabel>
          <Select
            labelId="selected-language-label"
            id="selected-language"
            onChange={e => setStudentYear(e.target.value)}
            label="سنة"
            fullWidth
            style={{backgroundColor: '#ffffff'}} // White background for better contrast
          >
            {years.map((year, index) => (
              <MenuItem key={`year${index}`} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Buttons for Actions */}
        <Grid container spacing={2} style={{marginBottom: '20px'}}>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={onGetYearlyCostButtonClick}
              fullWidth
              style={{padding: '10px 0'}} // Adds vertical padding for better clickability
            >
              عرض
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSaveYearlyCostButtonClick}
              fullWidth
              style={{padding: '10px 0'}} // Adds vertical padding for better clickability
            >
              حفظ
            </Button>
          </Grid>
        </Grid>
        {/* Styled Table */}
        <Paper elevation={3} style={{overflowX: 'auto'}}>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{fontWeight: 'bold'}}>الصف</TableCell>
                <TableCell align="center" style={{fontWeight: 'bold'}}>التكلفة السنوية</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">الأول</TableCell>
                <TableCell align="center">
                  <TextField
                    id="first"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={first}
                    onChange={(ev) => setFirst(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الثاني</TableCell>
                <TableCell align="center">
                  <TextField
                    id="second"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={second}
                    onChange={(ev) => setSecond(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الثالث</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setThird(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الرابع</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setForth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الخامس</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setFifth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">السادس</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setSixth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">السابع</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setSeventh(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الثامن</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setEighth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">التاسع</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setNinth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">العاشر </TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setTenth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الحادي عشر</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setEleventh(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">الثاني عشر</TableCell>
                <TableCell align="center">
                  <TextField
                    id="third"
                    dir="rtl"
                    required
                    margin="dense"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={third}
                    onChange={(ev) => setTwelfth(ev.target.value)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default YearlyPaymentsTable;
