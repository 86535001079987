import React, {useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, TextField} from '@mui/material';
import axios from 'axios';
import swal from "sweetalert"; // For making API calls

const CreateAdminForm = ({onAdminCreated, BASE_URL}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [supervisorAdmin, setSupervisorAdmin] = useState(false);
    const [classes, setClasses] = useState({});

    // Define an array for grades 1 through 12
    const grades = [
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
      "الحادي عشر",
      "الثاني عشر"
    ];

    const fetchQr = async (id) => {
      try {
        const params = new URLSearchParams({id: id});
        const fullUrl = `${BASE_URL}generateAdminQRCodeLogin?${params.toString()}`;
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            'Accept': '*/*',
            'Access-Control-Allow-Origin': '*/*',
          },
        });

        if (response.ok) {
          const qrBlob =  await response.blob();
          return URL.createObjectURL(qrBlob);
        } else {

          return null;
        }
      } catch (error) {
        swal('خطأ في جلب رمز الاستجابة السريعة:', error);
        return null;
      }
    };
    const handleClassChange = (grade, checked) => {
      setClasses(prevClasses => ({
        ...prevClasses,
        [ grade ]: checked,
      }));
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      // Prepare the admin data
      const newAdmin = {
        firstName,
        lastName,
        email,
        supervisorAdmin,
        classes,
      };

      try {
        const apiUrl = BASE_URL + 'saveAdminInfo';

        const response = await axios.post(apiUrl, newAdmin);

        // Call the callback function to handle what happens after an admin is created
        onAdminCreated(response.data);



        if (response.status === 200) {
          console.log(response.data)
          const qrUrl = await fetchQr(response.data.id);

          // Create a custom div to show the image
          const contentDiv = document.createElement('div');
          contentDiv.style.textAlign = 'right';  // Align text to the right for Arabic

          const titleElement = document.createElement('h2');
          titleElement.textContent = "تم إنشاء الحساب بنجاح";
          titleElement.style.color = '#1976d2';
          titleElement.style.marginBottom = '20px';
          titleElement.style.textAlign = 'center';  // Center the title
          contentDiv.appendChild(titleElement);

          // Create and format the user details
          const textElement = document.createElement('div');
          textElement.style.marginBottom = '20px';
          textElement.style.fontSize = '16px';
          textElement.style.lineHeight = '1.8';
          textElement.style.direction = 'rtl';
          textElement.innerHTML = `اسم المستخدم: ${email}<br>كلمة المرور: ${response.data.password}`;
          contentDiv.appendChild(textElement);

          const imgElement = document.createElement('img');
          imgElement.src = qrUrl;  // Use the fetched QR code URL
          imgElement.alt = "رمز الاستجابة السريعة";
          imgElement.style.marginTop = '20px';
          imgElement.style.width = '100%';
          imgElement.style.maxWidth = '200px';
          imgElement.style.display = 'block';
          imgElement.style.margin = '0 auto'; // Center the QR code
          contentDiv.appendChild(imgElement);

          // Displaying the success alert with the QR code image
          swal({
            title: null,  // Set title to null to remove the default title
            content: contentDiv,
            buttons: {
              confirm: {
                text: 'حسناً',
                value: true,
                visible: true,
                className: 'swal-button--confirm',
                closeModal: true
              }

              /* swal("Success", "User name: " + email + "\nPassword: " + data.password)
               .then((value) => {
                 window.location.href = "/addAdmin";
               });*/

            }
          })

        } else {
          await swal("Failed", "error");
        }
        // Clear the form after submission
        setFirstName('');
        setLastName('');
        setEmail('');
        setSupervisorAdmin(false);
        setClasses({});
      } catch
        (error) {
        await swal('خطأ في إنشاء المسؤول:', error);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="الاسم الأول"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="اسم العائلة"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="بريد إلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={supervisorAdmin}
                  onChange={(e) => setSupervisorAdmin(e.target.checked)}
                />
              }
              label="مشرف اداري"
            />
          </Grid>
          <Grid item xs={12}>
            <h3>الفصول الدراسية</h3>
            <Grid container spacing={1}>
              {grades.map(grade => (
                <Grid item xs={4} key={grade}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={classes[ grade ] || false}
                        onChange={(e) => handleClassChange(grade, e.target.checked)}
                      />
                    }
                    label={`${grade}`}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              إنشاء المشرف
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
;

export default CreateAdminForm;
